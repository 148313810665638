import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  baseUrl = `${environment.BaseApiURL}/api/user/`;
  jwtHelper = new JwtHelperService();
  decodedToken: any;

  constructor(private http: HttpClient) { }

  login(model: any) {
    return this.http
      .post(`${this.baseUrl}login`, model).pipe(
        map((response: any) => {
          const user = response;
          if (user) {
            localStorage.setItem('token', user.token);
            localStorage.setItem('userName', user.user);
            localStorage.setItem('emailConfirmed', user.emailConfirmed);
            let permisions = '';
            for (let permission of user.permission) {
              console.log(permission);
              permission += '|';
              permisions += permission;
            }
            localStorage.setItem('accessLevel', permisions);
            this.decodedToken = this.jwtHelper.decodeToken(user.token);
            if (permisions == '')
              return false;
            else
              return true;
          }
        })
      );
  }

  register(model: any) {
    return this.http.post(`${this.baseUrl}register`, model);
  }

  loggedIn() {
    const token = localStorage.getItem('token');
    if (token != null && this.jwtHelper.isTokenExpired(token)) {
      localStorage.removeItem('token');
      localStorage.removeItem('userName');
      localStorage.removeItem('accessLevel');
    }
    return !this.jwtHelper.isTokenExpired(token);
  }
}
