import { Component, OnInit, Type } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ComicsService } from '../_services/Comics.service';
import { SystemFIleService } from '../_services/SystemFIle.service';

@Component({
  selector: 'app-EditComic',
  templateUrl: './EditComic.component.html',
  styleUrls: ['./EditComic.component.scss']
})
export class EditComicComponent implements OnInit {
  isLoading: boolean = true;

  comic: any;

  places: any = [];
  states: any = [];
  cities: any = [];

  constructor(private fileService: SystemFIleService, private comicService: ComicsService, private toastTr: ToastrService, private router: Router) { }

  ngOnInit() {
    this.places = GlobalVariables.places;
    this.getComic();
  }

  changeRegion(key: string = '', value: string = '') {
    if (key == 'country') {
      this.comic.country = value;
      let country = this.places.find(p => p.country == this.comic.country);
      this.states = country.states;
      this.comic.state = this.states[0].initials;
      let state = this.states.find(c => c.initials == this.comic.state);
      this.cities = state.cities;
      this.comic.city = this.cities[0];
    }

    if (key == 'state') {
      this.comic.state = value;
      let state = this.states.find(c => c.initials == this.comic.state);
      this.cities = state.cities;
      this.comic.city = this.cities[0];
    }

    if (key == 'city') {
      this.comic.city = value;
    }
  }

  refreshLocalization() {
    this.comic.geoLocalization.latitude = this.comic.geoLocalization.latitude.toString();
    this.comic.geoLocalization.longitude = this.comic.geoLocalization.longitude.toString();
    this.comic.geoLocalization.elevation = this.comic.geoLocalization.elevation.toString();
  }


  getComic() {
    let comicId = localStorage.getItem('itemIdToEdit');
    if (comicId == '') {
      this.comic = {
        name: '',
        enable: false,
        comicLinkUrl: 'http://',
        country: 'Brasil',
        state: 'SC',
        city: 'Joinville',
        reward: {
          coins: 0,
          score: 0
        },
        imageFile: null,
        geoLocalization: {
          latitude: '0',
          longitude: '0',
          elevation: '0',
          type: 'fixed',
          ownerType: 'comic'
        }
      };

      let country = this.places.find(p => p.country == this.comic.country);
      this.states = country.states;

      let state = this.states.find(c => c.initials == this.comic.state);
      this.cities = state.cities;

      this.isLoading = false;
    } else {
      this.comicService.getById(comicId).subscribe((comic: any) => {
        this.comic = comic;
        if (!this.comic.reward)
          this.comic.reward = {
            coins: 0,
            score: 0
          };

        let country = this.places.find(p => p.country == this.comic.country);
        this.states = country.states;

        let state = this.states.find(c => c.initials == this.comic.state);
        this.cities = state.cities;
        
        this.isLoading = false;
      }, error => {
        this.toastTr.error('erro ao buscar comic');
        console.log(error);
      });
    }
  }

  changeReward(type: string, input: any) {
    input.value = Math.floor(input.value);
    if (type == 'coin') {
      this.comic.reward.coins = Number(input.value);
    }

    if (type == 'score') {
      this.comic.reward.score = Number(input.value);
    }
  }

  updateComic(returnToList: boolean) {
    let comicId = localStorage.getItem('itemIdToEdit');
    if (comicId == '') {
      this.comicService.add(this.comic).subscribe((comic: any) => {
        this.comic = comic;
        console.log(this.comic);
        this.isLoading = false;
        if (returnToList)
          this.backToList();
        else
          localStorage.setItem('itemIdToEdit', this.comic.id);
      }, error => {
        this.toastTr.error("Erro when create comic");
        console.log(error);
      });
    }
    else {
      this.isLoading = true;
      this.comicService.Update(this.comic).subscribe((comic: any) => {
        this.comic = comic;
        this.isLoading = false;
        this.toastTr.success("Comic atualizado com sucesso");
        this.isLoading = false;
        if (returnToList)
          this.backToList();
      }, error => {
        this.toastTr.success("Erro ao atualizar Comic");
        console.log(error);
      });
    }
  }

  backToList() {
    this.router.navigate(['AddOrEditComics']);
    localStorage.setItem('itemIdToEdit', '');
  }

  getImage() {
    if (this.comic.imageFile == null || this.comic.imageFile == undefined) {
      return '../../assets/img/EditContact/NoImage.jpg';
    }
    else
      return this.comic.imageFile.fileURL;
  }


  changeComicImage(event) {
    if (event.target.files && event.target.files.length) {

      let fileToAdd = event.target.files[0];
      this.isLoading = true;
      const reader = new FileReader();
      reader.readAsDataURL(fileToAdd);
      console.log(fileToAdd);

      reader.onload = () => {
        var uploadImage = () => {
          this.fileService.upload(fileToAdd, `Comics/${this.comic.id}`, 'Image').subscribe((sysFile: any) => {
            this.comic.imageFile = sysFile;
            this.updateComic(false);
          }, error => {
            this.toastTr.error("Erro when upload comic image");
            console.log(error);
          });
        }

        let comicId = localStorage.getItem('itemIdToEdit');
        console.log(comicId);

        if (comicId == '') {
          this.comicService.add(this.comic).subscribe((comic: any) => {
            this.comic = comic;
            localStorage.setItem('itemIdToEdit', this.comic.id);
            uploadImage();
          }, error => {
            this.toastTr.error("Erro when create comic");
            console.log(error);
          });
        }
        else {
          uploadImage();
        }
      }
    }
  }
}
