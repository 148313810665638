import { TypeScriptEmitter } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ItemService } from '../_services/Item.service';
import { SystemFIleService } from '../_services/SystemFIle.service';

@Component({
  selector: 'app-EditItem',
  templateUrl: './EditItem.component.html',
  styleUrls: ['./EditItem.component.scss']
})
export class EditItemComponent implements OnInit {
  isLoading: boolean = true;

  item: any;

  rarity: string[];
  types: string[];
  actions: string[];
  get isArtifact(): boolean {
    return this.item.type == this.types[1];
  }
constructor(private fileService: SystemFIleService, private itemService: ItemService, private toastTr: ToastrService, private router: Router) { }

ngOnInit() {
  this.rarity = GlobalVariables.Rarity;
  this.types = GlobalVariables.items.types;
  this.actions = GlobalVariables.items.ItemActions;
  this.getItem();
}

getItem() {
  let itemId = localStorage.getItem('itemIdToEdit');
  if (itemId == '') {
    this.item = {
      reward: {
        coins: 0,
        score: 0
      },
      name: '',
      enable: false,
      description: '',
      type: this.types[0],
      itemAction: this.actions[0],
      rarity: this.rarity[0],
      imagefile: null
    }
    this.isLoading = false;
  } else {
    this.itemService.getById(itemId).subscribe((item: any) => {
      this.item = item;
      if (!this.item.reward)
        this.item.reward = {
          coins: 0,
          score: 0
        };
      if (this.item.itemAction == undefined || this.item.itemAction == null) {
        this.item.itemAction = this.actions[0];
      }
      this.isLoading = false;
    }, error => {
      this.toastTr.error('erro ao buscar item');
      console.log(error);
    });
  }
}

changeReward(type: string, input: any) {
  input.value = Math.floor(input.value);
  if (type == 'coin') {
    this.item.reward.coins = Number(input.value);
  }

  if (type == 'score') {
    this.item.reward.score = Number(input.value);
  }
}

updateItem(returnToList: boolean) {
  let itemId = localStorage.getItem('itemIdToEdit');
  if (itemId == '') {
    this.itemService.add(this.item).subscribe((item: any) => {
      this.item = item;
      this.isLoading = false;
      if (returnToList)
        this.backToList();
    }, error => {
      this.toastTr.error("Erro when create item");
      console.log(error);
    });
  }
  else {
    this.isLoading = true;
    this.itemService.Update(this.item).subscribe((item: any) => {
      this.item = item;
      this.isLoading = false;
      this.toastTr.success("Item atualizado com sucesso");
      this.isLoading = false;
      if (returnToList)
        this.backToList();
    }, error => {
      this.toastTr.success("Erro ao atualizar Item");
      console.log(error);
    });
  }
}

backToList() {
  this.router.navigate(['AddOrEditItem']);
  localStorage.setItem('itemIdToEdit', '');
}

getImage() {
  if (this.item.imageFile == null || this.item.imageFile == undefined) {
    return '../../assets/img/EditContact/NoImage.jpg';
  }
  else
    return this.item.imageFile.fileURL;
}


changeItemImage(event) {
  if (event.target.files && event.target.files.length) {

    let fileToAdd = event.target.files[0];
    this.isLoading = true;
    const reader = new FileReader();
    reader.readAsDataURL(fileToAdd);
    console.log(fileToAdd);

    reader.onload = () => {
      var uploadImage = () => {
        this.fileService.upload(fileToAdd, `Items/${this.item.id}`, 'Image').subscribe((sysFile: any) => {
          this.item.imageFile = sysFile;
          this.updateItem(false);
        }, error => {
          this.toastTr.error("Erro when upload item image");
          console.log(error);
        });
      }

      let itemId = localStorage.getItem('itemIdToEdit');
      console.log(itemId);

      if (itemId == '') {
        this.itemService.add(this.item).subscribe((item: any) => {
          this.item = item;
          localStorage.setItem('itemIdToEdit', this.item.id);
          uploadImage();
        }, error => {
          this.toastTr.error("Erro when create item");
          console.log(error);
        });
      }
      else {
        uploadImage();
      }
    }
  }
}
}
