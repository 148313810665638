import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArchetypeService {
    baseUrl = `${environment.BaseApiURL}/api/archetype/`;
  
    constructor(private http: HttpClient) { }

    getAllArchetypes() {
      return this.http.get(`${this.baseUrl}`);
    }
    
    addArchetype(archetype: any = []) {
      return this.http.post(`${this.baseUrl}`, archetype);
    }
}
