<div *ngIf="!isLoading && haveDisponibleItems()" class="block">
    <div class="row form-inline line-form mt-3">
        <div class="col-6 header">
            <h6>
                Item da Loja
            </h6>
        </div>
    </div>
    <div class="row body">
        <div class="col-12">
            <div class="row">
                <div *ngIf="isCreate()">
                    <div style="min-width: 150px;" class=" col-12 btn-group" dropdown>
                        <button style="width: 100%;" id="button" dropdownToggle type="button" class="mx-auto d-block dropdown-toggle" aria-controls="dropdown">{{storeItem.name}}<span class="caret"></span></button>
                        <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu" aria-labelledby="button">
                            <div class="col-12" *ngFor="let item of disponibleItems">
                                <li class="" (click)="storeItem.itemId = item.id; storeItem.name = item.name; storeItem.imgURL = item.imgURL" role="menuitem">
                                    <a class="dropdown-item" style="cursor: pointer;">
                                        <h4 class="text-center"> {{item.name}} </h4>
                                        <img [src]="item.imgURL">
                                    </a>
                                </li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12" *ngIf="hasImage()">
                    <div style="width: 100%" *ngIf="!isCreate()">
                        <h4> {{storeItem.name}}</h4>
                    </div>
                    <img style="width: 200px;" class="rounded" [src]="storeItem.imgURL">
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12">
                    <h6><b>Descrição do Ítem na LOJA</b></h6>
                </div>
            </div>
            <div class="row description">
                <div class="col-12">
                    <textarea [(ngModel)]="storeItem.storeDescription" class="col-12 mt-2" aria-label="With textarea"></textarea>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-1">
                    <h6>Moedas:</h6>
                </div>
                <div class="col-4">
                    <input #coins [(ngModel)]="storeItem.price" type="number">
                </div>
            </div>
        </div>

    </div>
    <div class="d-flex mt-3 mb-3">
        <button class="w-25 btn btn-secondary" (click)="backToList()">Voltar</button>
        <button class="mt-auto w-25 btn btn-primary ml-auto" (click)="updateItem()">Atualizar</button>
    </div>
</div>
<div *ngIf="!isLoading && !haveDisponibleItems()" class="block">
    <h2 style="color: red;" class="text-center mt-5">Você precisa adicionar novos artefatos no pagina de itens</h2>
</div>

<div *ngIf="isLoading" class="d-flex justify-content-center " style="margin-top: 40vh; ">
    <div class="spinner-grow text-primary mb-auto " style="width: 5rem; height: 5rem; ">
        <span class="sr-only ">Loading...</span>
    </div>
</div>