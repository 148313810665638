<div *ngIf="!isLoading" class="block">
  <div class="row form-inline line-form mt-3">
    <div class="col-6">
      <h2 class="custom-h2">
        <div class="ico-wrap">
          <span class="mbr-iconfont fas fa fa-rocket"></span>
          PARCEIROS
        </div>
      </h2>
    </div>
    <div class="col-6">
      <button class="float-right btn-success-custom " (click)="add()"><i class="fas fa-plus mr-2"></i>ADICIONAR
        PARCEIRO</button>
    </div>
  </div>
  <div class="row filter">
    <div class="col-1">
      <div class="row mt-2">
        <h6 class="col-5" style="font-weight: bold; font-size: 20px;">FILTRO</h6>
      </div>
    </div>
    <div class="col-4">
      <div class="row mt-2">
        <h6 class="col-5 text-right">NOME:</h6>
        <input type="text" class="col-7" [(ngModel)]="filterName">
      </div>
    </div>
    <div class="col-1">
    </div>
  </div>
  <table class="table table-striped ">
    <thead class="thead-custom ">
      <tr>
        <th>IMAGEM</th>
        <th>NOME</th>
        <th></th>
      </tr>
    </thead>
    <tbody *ngIf="filterPartners.length > 0">
      <tr *ngFor="let partner of filterPartners ">
        <td>
          <img [src]="getImage(partner.id)" style="height: 50px;">
        </td>
        <td class="align-middle">{{partner.partnerName}}</td>
        <td>
          <div class="btn-group" data-toggle="buttons ">
            <button class="button-custom-edit" (click)="edit(partner.id, partnerModal) "><i
                class="fas fa-pencil-alt"></i></button>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot *ngIf="filterPartners.length == 0">
      <tr>
        <td colspan="7 " class="text-center ">
          <h4>
            Nenhum parceiro encontrado
          </h4>
        </td>
      </tr>
    </tfoot>
  </table>
</div>

<div *ngIf="isLoading " class="d-flex justify-content-center " style="margin-top: 40vh; ">
  <div class="spinner-grow text-primary mb-auto " style="width: 5rem; height: 5rem; ">
    <span class="sr-only ">Loading...</span>
  </div>
</div>

<!-- Modal -->
<ng-template #partnerModal>
  <div class="modal-header ">
    <h4 class="modal-title pull-left ">{{partnerSelected.partnerName}}</h4>
    <button type="button " class="close pull-right " aria-label="Close " (click)="modalRef.hide() ">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body ">
    <div class="row g-3">

      <div class="col-12">
        <img class="rounded mx-auto d-block" [src]="getImage(partnerSelected.id)" style="cursor: pointer;height: 300px;" alt="noImage" (click)="imageItem.click()">
        <input type="file" #imageItem class="mx-auto btn-file" (change)="changePartnerImage($event)" style="display: none;">
        <figcaption class="figure-caption text-center">Click to change.</figcaption>
      </div>

      <div class="col-12">
        <label for="userName" class="form-label"><b>User Name:</b> {{partnerSelected.userName}}</label>
      </div>

      <div class="col-12">
        <label for="address" class="form-label"><b>Address:</b> {{partnerSelected.address}}</label>
      </div>

      <div class="col-md-5">
        <label for="country" class="form-label"><b>Country:</b> {{partnerSelected.country}}</label>
      </div>

      <div class="col-md-3">
        <label for="state" class="form-label"><b>State:</b> {{partnerSelected.state}}</label>
      </div>

      <div class="col-md-4">
        <label for="city" class="form-label"><b>City:</b> {{partnerSelected.city}}</label>
      </div>

      <div class="col-sm-6">
        <label for="inChargeFirstName" class="form-label"><b>In charge Name:</b> {{partnerSelected.inChargeFirstName}}
          {{partnerSelected.inChargeLastName}}</label>
      </div>

      <div class="col-12">
        <label for="inChargeEmail" class="form-label"><b>In charge Email:</b> {{partnerSelected.inChargeEmail}}</label>
      </div>
      <div class="col-12">
        <hr>
      </div>
      <div class="mt-3 mb-3 col-12">
        <button class="btn btn-primary btn-modal" (click)="AddVoucher()">Add Voucher</button>
      </div>
    </div>
  </div>
</ng-template>