import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { VersionService } from '../_services/Version.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  
  cmsVersion: string;
  apiVersion: string;

  constructor( public router: Router, public versionService: VersionService) { }
  userName: string;
  
  ngOnInit() {
    this.userName = localStorage.getItem('userName');
    this.getCmsVersion();
    this.getApiVersion();
  }

  Logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('accessLevel');
    this.router.navigate(['Login']);
  }
  
  getCmsVersion()
  {
    this.cmsVersion = `cms: ${GlobalVariables.version}_${environment.Name}`;
  }

  getApiVersion()
  {
    this.versionService.get().subscribe((r: any) => {
      this.apiVersion = `api: ${r.version}`;
    })
  }
  
  isAdm()
  {
    return localStorage.getItem('accessLevel') != undefined && localStorage.getItem('accessLevel').includes('Administrator');
  }
  isPartner()
  {
    return localStorage.getItem('accessLevel') != undefined && localStorage.getItem('accessLevel').includes('Partner');
  }
}
