import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from '../_services/Store.service';

@Component({
  selector: 'app-EditGameStoreItem',
  templateUrl: './EditGameStoreItem.component.html',
  styleUrls: ['./EditGameStoreItem.component.scss']
})
export class EditGameStoreItemComponent implements OnInit {

  constructor(public storeService: StoreService, public toastTr: ToastrService, private router: Router) { }
  get isLoading(): boolean {
    return this.isGettingItem || this.isGettingDisponibleItems || this.isUpdateItem;
  }
  isGettingItem: boolean = true;
  isGettingDisponibleItems: boolean = true;
  isUpdateItem: boolean = false;
  storeItem: any;
  disponibleItems: any[];

  ngOnInit() {
    this.getItem();
    this.getDisponibleItems();
  }

  haveDisponibleItems()
  {
    if(this.storeItem != null && this.storeItem != undefined)
      return true;

    return this.disponibleItems != undefined &&this.disponibleItems.length > 0;
  }

  isCreate()
  {
    return localStorage.getItem('itemIdToEdit') == '';
  }

  hasImage() {
    if (this.storeItem != null && this.storeItem.imgURL != undefined && this.storeItem.imgURL != null)
      return true;
    else
      return false;
  }

  getDisponibleItems() {
    this.isGettingDisponibleItems = true;
    this.storeService.getDisponibleItems().subscribe((disponiblesItems: any[]) => {
      this.disponibleItems = disponiblesItems;
      console.log(this.disponibleItems);
      this.isGettingDisponibleItems = false;
    }, error => {
      this.toastTr.error('erro ao buscar item');
      console.log(error);
    });
  }


  getItem() {
    let itemId = localStorage.getItem('itemIdToEdit');
    if (itemId == '') {
      this.storeItem = {
        itemId: '',
        enable: false,
        storeDescription: '',
        price: 0
      }
      this.isGettingItem = false;
    } else {
      this.storeService.getById(itemId).subscribe((storeItem: any) => {
        this.storeItem = storeItem;
        console.log(this.storeItem);
        this.isGettingItem = false;
      }, error => {
        this.toastTr.error('erro ao buscar item');
        console.log(error);
      });
    }
  }

  updateItem() {
    let itemId = localStorage.getItem('itemIdToEdit');
    console.log(this.storeItem);
    if (itemId == '') {
      this.storeService.add(this.storeItem).subscribe((storeItem: any) => {
        this.storeItem = storeItem;
        this.isUpdateItem = false;

        this.backToList();
      }, error => {
        this.toastTr.error("Erro when create item");
        console.log(error);
      });
    }
    else {
      this.isUpdateItem = true;
      this.storeService.Update(this.storeItem).subscribe((storeItem: any) => {
        this.storeItem = storeItem;
        this.isUpdateItem = false;
        this.toastTr.success("Item atualizado com sucesso");
        this.isUpdateItem = false;
        this.backToList();
      }, error => {
        this.toastTr.success("Erro ao atualizar Item");
        console.log(error);
      });
    }
  }

  backToList() {
    this.router.navigate(['GameStore']);
    localStorage.setItem('itemIdToEdit', '');
  }

}
