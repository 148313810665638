<div *ngIf="!isLoading" class="col-md-7 col-lg-8 m-auto bg-light">
  <h4 class="mb-3 mt-3 text-center">PROFILE</h4>
    <div class="row g-3">

      <div class="col-12">
        <img class="rounded mx-auto d-block" [src]="getImage()" alt="noImage">        
      </div>

      <div class="col-12">
        <label for="partnerName" class="form-label"><b>Partner Name:</b> {{partner.partnerName}}</label>       
      </div>     

      <div class="col-12">
        <label for="userName" class="form-label"><b>User Name:</b> {{partner.userName}}</label>
      </div>

      <div class="col-12">
        <label for="address" class="form-label"><b>Address:</b> {{partner.address}}</label>
      </div>

      <div class="col-md-5">
        <label for="country" class="form-label"><b>Country:</b> {{partner.country}}</label>
      </div>

      <div class="col-md-3">
        <label for="state" class="form-label"><b>State:</b> {{partner.state}}</label>
      </div>

      <div class="col-md-4">
        <label for="city" class="form-label"><b>City:</b> {{partner.city}}</label>
      </div>

      <div class="col-sm-6">
        <label for="inChargeFirstName" class="form-label"><b>In charge Name:</b> {{partner.inChargeFirstName}}  {{partner.inChargeLastName}}</label>
      </div>

      <div class="col-12">
        <label for="inChargeEmail" class="form-label"><b>In charge Email:</b> {{partner.inChargeEmail}}</label>
      </div>
      <div class="col-12">
      <hr>
      </div>
    </div>
</div>
<div *ngIf="isLoading" class="d-flex justify-content-center " style="margin-top: 40vh; ">
  <div class="spinner-grow text-primary mb-auto " style="width: 5rem; height: 5rem; ">
      <span class="sr-only ">Loading...</span>
  </div>
</div>