import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  baseUrl = `${environment.BaseApiURL}/api/Store/`;

  constructor(private http: HttpClient) { }
 
  getAll() {
    return this.http.get(`${this.baseUrl}?includeDisables=true`);
  }

  getDisponibleItems() {
    return this.http.get(`${this.baseUrl}DisponiblesToCreate`);
  }

  add(storeItem: any) {
    return this.http.post(`${this.baseUrl}`, storeItem);
  }

  Update(storeItem: any) {
    return this.http.put(`${this.baseUrl}`, storeItem);
  }

  getById(id: string) {
    return this.http.get(`${this.baseUrl}${id}`);
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}${id}`);
  }
}
