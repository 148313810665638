<nav class="navbar navbar-expand-md navbar-dark top">
    <div class="container">
        <img src="../../assets/img/LogoWhite.svg" alt="Route Raider">
        <a class="navbar-brand" routerLink="oquefazer">
            Route Raiders Developers Editor</a>
        <ul class="navbar-nav">
            <li class="nav-item dropdown" dropdown>
                <a dropdownToggle class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="cursor: pointer">
                        {{userName}}
                    </a>
                <div *dropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <div *ngIf="isAdm()" role="separator" class="divider"></div>
                    <a *ngIf="isPartner()" class="dropdown-item" href="Profile" style="cursor: pointer">
                        Profile
                    </a>
                    <a *ngIf="isAdm()" class="dropdown-item" href="AddPartner" style="cursor: pointer">
                        Adicionar Game Master
                    </a>
                    <div role="separator" class="divider"></div>
                    <a class="dropdown-item" style="cursor: pointer" (click)="Logout()">
                        Sair
                    </a>
                </div>
            </li>
        </ul>
    </div>
    <p style="color: white;">{{cmsVersion}}<br>{{apiVersion}}</p>
</nav>