<div *ngIf="!isLoading">
    <div class="row mt-4">
        <img class="col-sm-1 topImg d-block rounded" src="../../assets/img/EditContact/ContatoImg.svg">
        <div class="textContact" *ngIf="isNewContact">
            <h3>Novo Contato</h3>
        </div>
        <div class="textContact" *ngIf="!isNewContact">
            <h3>Editando Contato</h3>
        </div>
    </div>
    <div class="TopPanel">
        <div class="row">
            <div class=" colunm w-25">
                <h6 class="d-block m-auto">IMAGEM THUMBNAIL <br>300 por 300 px - 72 dpi</h6>
                <img class="d-block" [src]="getImagePortrait()" style="cursor: pointer;" (click)="filePortrait.click()">
                <input type="file" #filePortrait class="mx-auto btn-file" (change)="changePortrait($event)" style="display: none;">
            </div>
            <div class="colunm w-75">
                <div class="row">
                    <div class="colunm w-50">
                        <div class="row pl-5">
                            <h6 class="TopLabel">Nome:</h6>
                            <input #contactName [value]="contact.name" (change)="contact.name = contactName.value" class="defaultInput">
                        </div>
                        <div class="row pl-5 country mt-3">
                            <h6 class="TopLabel">País:</h6>
                            <div class="btn-group" dropdown>
                                <button id="button" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown">{{contact.nationality}}<span class="caret"></span></button>
                                <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-scroll" role="menu" aria-labelledby="button">
                                    <div *ngFor="let place of places">
                                        <li (click)="contact.nationality = place.country;reloadCitiesAndStates();" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{place.country}}</a></li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div class="row pl-5  mt-3">
                            <h6 class="TopLabel">Estado:</h6>
                            <div class="btn-group" dropdown>
                                <button id="button" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown">{{contact.state}}<span class="caret"></span></button>
                                <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-scroll " role="menu" aria-labelledby="button">
                                    <div *ngFor="let state of states">
                                        <li (click)="contact.state = state.initials;reloadCitiesAndStates();" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{state.initials}}</a></li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div class="row pl-5  mt-3">
                            <h6 class="TopLabel">Cidade:</h6>
                            <div class="btn-group" dropdown>
                                <button id="button" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown">{{contact.city}}<span class="caret"></span></button>
                                <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-scroll" role="menu" aria-labelledby="button">
                                    <div *ngFor="let city of cities">
                                        <li (click)="contact.city = city;reloadCitiesAndStates();" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{city}}</a></li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="colunm w-50">
                        <div class="row ">
                            <h6 class="TopLabel mr-1">Raridade:</h6>
                            <div class="btn-group" dropdown>
                                <button id="button" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown">{{contact.rarity}}<span class="caret"></span></button>
                                <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-scroll " role="menu" aria-labelledby="button">
                                    <div>
                                        <li (click)="changeRarity('Classic')" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">Classic</a></li>
                                        <li (click)="changeRarity('Rare')" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">Rare</a></li>
                                        <li (click)="changeRarity('Epic')" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">Epic</a></li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <h6 class="TopLabel mr-1">Arquetipo:</h6>
                            <div class="btn-group" dropdown>
                                <button id="button" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown">{{contact.archetype}}<span class="caret"></span></button>
                                <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-scroll " role="menu" aria-labelledby="button">
                                    <div *ngFor="let archetype of archetypes">
                                        <li (click)="changeArchetype(archetype.name)" role="menuitem "><a class="dropdown-item " style="cursor: pointer; ">{{archetype.name}}</a></li>
                                    </div>
                                </ul>
                            </div>
                            <button class="bg_add_archetype" (click)="openModal(addArchetypeModal)"><i  class="fas fa-plus-circle button-custom-ico"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row container-fluid mx-auto">
        <div class="mt-3" *ngFor="let pose of getPoses();">
            <div>
                <div class="col-md-12 mt-2">
                    <h6>{{pose.tag}}</h6>
                    <img class="mt-2 imgPose" [src]="pose.systemFile.fileURL" alt="">
                    <div class="mt-3">
                        <button class="buttonDelete" style="background-color: red;color: white" (click)="openModal(confirmRemove);setRemove(pose.id, 'pose')"><i class="fas fa-trash-alt "></i></button>
                    </div>
                </div>
            </div>
        </div>
        <button class="bg_add_archetype" (click)="openModal(addPoseModal)"><i  class="fas fa-plus-circle button-custom-ico">Add Pose</i></button>
    </div>

    <div class="descriptionPanel mt-5 pt-2 pb-2 col-sm-12">
        <div class="row pl-4 pr-4">
            <div class="col-sm-12">
                <h6 class="row text-center">Descrição do Contato</h6>
                <div class="row">
                    <textarea [(ngModel)]="contact.description" class="col-sm-12" aria-label="With textarea"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="speakPanel mt-4 pt-2 pb-2 col-sm-12">
        <div class="row pl-4">
            <div class="col-sm-5">
                <div class="row">
                    <h6 class="text-center mr-2">Moedas</h6>
                    <input #coins [value]="contact.reward.coins" (change)="contact.reward.coins = roundToInt(coins.value);coins.value = contact.reward.coins;" type="number" class="mr-2 defaultInput">
                </div>
            </div>
            <div class="col-sm-1"></div>
            <div class="col-sm-5">
                <div class="row">
                    <h6 class="text-center mr-2">Score</h6>
                    <input #score [value]="contact.reward.score" (change)="contact.reward.score = roundToInt(score.value);score.value = contact.reward.score;" type="number" class="mr-2 defaultInput">
                </div>
            </div>
        </div>
    </div>
    <h4 class="mt-4">Falas:</h4>
    <div class="speakPanel mt-4 pt-2 pb-2 col-sm-12">
        <div class="row pl-4 pt-2 pb-2">
            <div class="col-sm-2">
                <div class="row">
                    <h6 class="text-center mr-2">Tags:</h6>
                    <button class="bg_add_archetype" (click)="addTagToSpeak()"><i  class="fas fa-plus-circle button-custom-ico"></i></button>
                    <button class="bg_add_archetype" (click)="removeTagToSpeak()"><i  class="fas fa-minus-circle" style="color: red;"></i></button>
                </div>
            </div>
            <div class="col-sm-10 container-fluid pr-5" *ngIf="speakToAdd.tags != undefined">
                <div>
                    <div class="row mb-2" *ngFor="let tag of speakToAdd.tags;let i = index">
                        <!-- TAG -->
                        <div class="btn-group mr-2" dropdown [dropup]="'true'">
                            <button id="button-dropup" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown-dropup">{{tag.name}}<span class="caret"></span></button>
                            <ul id="dropdown-dropup" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu" aria-labelledby="button-dropup">
                                <div *ngFor="let tagToChange of tagList">
                                    <li (click)="changeTag(tagToChange.name, i)" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{tagToChange.name}}</a></li>
                                </div>
                            </ul>
                        </div>

                        <!-- PARAMETER1 -->
                        <div *ngIf="tag.parameter1 != null">
                            <div *ngIf="getParameterType(tag, 0) == 'list'">
                                <div class="btn-group mr-2" dropdown [dropup]="'true'">
                                    <button id="button-dropup" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown-dropup">{{tag.parameter1}}<span class="caret"></span></button>
                                    <ul id="dropdown-dropup" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu" aria-labelledby="button-dropup">
                                        <div *ngFor="let param of getParamList(tag, 0)">
                                            <li (click)="changeParameter(i, 0, param)" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{param}}</a></li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div *ngIf="getParameterType(tag, 0) == 'number'">
                                <input #param1 [value]="tag.parameter1" type="number" (change)="changeParamNumber(i, 0, param1.value);param1.value = tag.parameter1" class="mr-2 defaultInput-sm">
                            </div>
                        </div>

                        <!-- PARAMETER2 -->
                        <div *ngIf="tag.parameter2 != null" class="btn-group mr-2" dropdown [dropup]="'true'">
                            <div *ngIf="getParameterType(tag, 1) == 'list'">
                                <div class="btn-group mr-2" dropdown [dropup]="'true'">
                                    <button id="button-dropup" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown-dropup">{{tag.parameter2}}<span class="caret"></span></button>
                                    <ul id="dropdown-dropup" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu" aria-labelledby="button-dropup">
                                        <div *ngFor="let param of getParamList(tag, 1)">
                                            <li (click)="changeParameter(i, 1, param)" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{param}}</a></li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div *ngIf="getParameterType(tag, 1) == 'number'">
                                <input #param2 [value]="tag.parameter2" type="number" (change)="changeParamNumber(i, 1, param2.value);param2.value = tag.parameter2" class="mr-2 defaultInput-sm">
                            </div>
                        </div>

                        <!-- PARAMETER3 -->
                        <div *ngIf="tag.parameter3 != null" class="btn-group mr-2" dropdown [dropup]="'true'">
                            <div *ngIf="getParameterType(tag, 2) == 'list'">
                                <div class="btn-group mr-2" dropdown [dropup]="'true'">
                                    <button id="button-dropup" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown-dropup">{{tag.parameter3}}<span class="caret"></span></button>
                                    <ul id="dropdown-dropup" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu" aria-labelledby="button-dropup">
                                        <div *ngFor="let param of getParamList(tag, 2)">
                                            <li (click)="changeParameter(i, 2, param)" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{param}}</a></li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div *ngIf="getParameterType(tag, 2) == 'number'">
                                <input #param3 [value]="tag.parameter3" type="number" (change)="changeParamNumber(i, 2, param3.value);param3.value = tag.parameter3" class="mr-2 defaultInput-sm">
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row pl-4 pr-4">
            <textarea [(ngModel)]="speakToAdd.text" class="col-sm-12" aria-label="With textarea"></textarea>
        </div>
        <div class="row pl-4 pr-4 mt-2">
            <button class="button-custom col-sm-12" (click)="addSpeak()">Adicionar Fala</button>
        </div>
        <div class="row pl-4 pr-4 mt-4">
            <div class="col-sm-12" *ngFor="let contactSpeak of contact.contactSpeaks">
                <div class="row mt-3">
                    <div class="col-sm-7 pt-1" style="background-color: white;">
                        <h6>{{contactSpeak.text}}</h6>
                    </div>
                    <div class="col-sm-1">
                    </div>
                    <div class="col-sm-2" style="background-color: white;">
                        <div class="row pl-2 pt-1" *ngFor="let tag of contactSpeak.tags">
                            <h6>{{tag.name}}</h6>
                        </div>
                    </div>
                    <div class="col-sm-2" style="background-color: transparent;">
                        <div class="ml-5">
                            <!-- <button class="button-custom mr-1"><i class="fas fa-user-edit "></i></button> -->
                            <button (click)="openModal(confirmRemove);setRemove(contactSpeak.id, 'speak');" class="button-custom-trash ml-1" style="background-color: red;color: white"><i class="fas fa-trash-alt "></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex mt-3 mb-3">
        <button class="w-25 btn btn-secondary" (click)="back()">Voltar</button>
        <button class="mt-auto w-25 btn btn-primary ml-auto" (click)="updateContact()">Atualizar</button>
    </div>
</div>
<div *ngIf="isLoading " class="d-flex justify-content-center " style="margin-top: 40vh; ">
    <div class="spinner-grow text-primary mb-auto " style="width: 5rem; height: 5rem; ">
        <span class="sr-only ">Loading...</span>
    </div>
</div>


<!-- Modal ADD Confirm-->
<ng-template #confirmModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{confirmMsg}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
    </div>
    <div class="modal-body">
        <div class="d-flex mt-5 mb-3">
            <button class="mr-auto w-25 btn btn-primary" (click)="modalRef.hide()">Cancelar</button>
            <button class="mt-auto w-25 btn btn-success ml-auto" (click)="cofirmModalSubmit()">Adicionar</button>
        </div>
    </div>
</ng-template>

<!-- Modal ADD Arquetype-->
<ng-template #addArchetypeModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Novo Arquétipo</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <h6 class="modalArchetypeLabel">Nome:</h6><input class="modalArchetypeInput" #newArchetype>
            <button class="modalArchetypeButton" (click)="addArchetype(newArchetype.value)">Adicionar</button>
        </div>
    </div>
</ng-template>

<!-- Modal ADD Pose-->
<ng-template #addPoseModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Nova Pose</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <h6 class="modalArchetypeLabel">Nome:</h6><input class="modalArchetypeInput" #newPoseName>
            <button class="modalArchetypeButton" (click)="filePose.click()">Adicionar</button>
            <input type="file" #filePose class="mx-auto btn-file" (change)="AddPose($event, newPoseName.value)" style="display: none;">
        </div>
    </div>
</ng-template>

<!-- Modal REMOVE -->
<ng-template #confirmRemove>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{removeMsg}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
    </div>
    <div class="modal-body">
        <div class="d-flex mt-5 mb-3">
            <button class="mr-auto w-25 btn btn-primary" (click)="modalRef.hide()">Cancelar</button>
            <button class="mt-auto w-25 btn btn-danger ml-auto" (click)="remove()">REMOVER</button>
        </div>
    </div>
</ng-template>