import { Time } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ItemService } from '../_services/Item.service';
import { QrCodeService } from '../_services/qrCode.service';
import { RouteService } from '../_services/route.service';
import { SystemFIleService } from '../_services/SystemFIle.service';

@Component({
  selector: 'app-EditRoute',
  templateUrl: './EditRoute.component.html',
  styleUrls: ['./EditRoute.component.scss']
})
export class EditRouteComponent implements OnInit {

  modalRef: BsModalRef;
  checkpointToRemoveId: string;

  isLoading: boolean = true;
  isNewRoute: boolean = true;

  route: any;
  allItems: any = [];
  treasures: any = [];

  countries: any = [];
  states: any = [];
  cities: any = [];
  possibleActivitiesList: any = [];
  routeTypes: any = [];
  routeDificulty: any = [];

  possibleActivitsMap: Map<string, boolean> = new Map();

  treasureQrCodeImage: any;
  treasureQrCodeImageUlr: any;

  set price(value: number) {
    this.route.price = Math.floor(value);
  }
  get price(): number {
    return this.route.price;
  }

  getTreasureQrCode() {
    this.qrCodeService.getImageById(this.route.treasureQrCode.id, 150).subscribe((image: any) => {
      var reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        this.treasureQrCodeImageUlr = reader.result;
      };
    }, error => {
      console.log(error);
    });
  }

  HasTreasure() {
    return this.route.routeTreasureId != undefined && this.route.routeTreasureId != null && this.route.routeTreasureId != GlobalVariables.emptyGuid;
  }

  get routeTreasureName(): string {
    if (!this.HasTreasure()) {
      return 'Escolha um tesouro';
    }
    else {
      var treasure = this.treasures.find(t => t.id == this.route.routeTreasureId);
      if (treasure != undefined)
        return treasure.name;
    }
  }
  minutes: number;
  set estimatedTimeMinutes(value: number) {
    if(value > 59)
    value = 59;
    if(value < 0)
    value = 0;
    this.minutes = Math.round(value);
    this.SetEstimatedHour();
  }

  get estimatedTimeMinutes() {
    return this.minutes;
  }

  hour: number;
  set estimatedTimeHour(value: number) {    
    if(value > 100)
    value = 100;
    if(value < 0)
    value = 0;
    this.hour = Math.round(value);
    this.SetEstimatedHour();
  }

  get estimatedTimeHour() {
    return this.hour;
  }

  SetEstimatedHour()
  {
    this.route.estimatedTimeHour = this.hour + (this.minutes/60);
  }

  constructor(private modalService: BsModalService, private fileService: SystemFIleService, private routeService: RouteService, private itenService: ItemService, private qrCodeService: QrCodeService, private toastTr: ToastrService, private router: Router) {
  }

  createCollections(db) {
    db.currentTarget.result.createObjectStore('PlayerPrefs');
  }

  editDialog(checkpointId) {
    localStorage.setItem('checkpointId', checkpointId);
    this.router.navigate(['EditDialog']);
  }

  ngOnInit() {
    this.countries = GlobalVariables.places;
    this.routeTypes = GlobalVariables.Routes.Types;
    this.routeDificulty = GlobalVariables.Dificulty;
    this.possibleActivitiesList = GlobalVariables.Routes.possibleActivities;
    this.getRoute();
    this.getItems();
  }

  getRouteTreasureImage() {
    if (!this.HasTreasure()) {
      return '../../assets/img/EditItem/ItemImg.svg';
    }

    let treasure = this.treasures.find(t => t.id == this.route.routeTreasureId);
    if (treasure) {
      return treasure.imageFile.fileURL;
    }
    else {
      return '../../assets/img/EditItem/ItemImg.svg';
    }

  }

  addCheckpoint() {
    this.isLoading = true;
    this.routeService.addCheckpoint(this.route.id).subscribe((route: any) => {
      this.route = route;
      this.isLoading = false;
    }, error => {
      this.toastTr.error("Erro ao adicionar checkpoint");
      console.log(error);
    });
  }

  getItems() {
    this.itenService.getAll().subscribe((items: any) => {
      this.allItems = items;
      this.treasures = this.allItems.filter(i => i.type.toUpperCase() == 'TREASURE');
    }, error => {
      console.log(error);
    });
  }

  activitieIsEnable(activitie: string) {
    let toReturn = this.possibleActivitsMap.get(activitie);
    if (toReturn) {
      return toReturn;
    }
    else {
      return false;
    }
  }

  getTreasureImage() {
    return "../../assets/img/EditItem/ItemImg.svg"
  }

  refreshActivities(activitie: string) {
    let activitieValue = this.possibleActivitsMap.get(activitie);
    let newValue;
    if (activitieValue) {
      newValue = !activitieValue;
    }
    else {
      newValue = true;
    }
    this.possibleActivitsMap.set(activitie, newValue);
    let activities = '';
    this.possibleActivitsMap.forEach((value, key) => {
      if (value) {
        activities += key + '|';
      }
    });
    this.route.possibleActivities = activities;
  }

  setPossibleActivitiesMap() {
    let activitiesSplit = this.route.possibleActivities.split('|');
    activitiesSplit.forEach(activitie => {
      this.possibleActivitsMap.set(activitie, true);
    });
  }

  changeRegion(key: string = '', value: string = '') {
    if (key == 'country') {
      this.route.country = value;
      let country = this.countries.find(p => p.country == this.route.country);
      this.states = country.states;
      this.route.state = this.states[0].initials;
      let state = this.states.find(c => c.initials == this.route.state);
      this.cities = state.cities;
      this.route.city = this.cities[0];
    }

    if (key == 'state') {
      this.route.state = value;
      let state = this.states.find(c => c.initials == this.route.state);
      this.cities = state.cities;
      this.route.city = this.cities[0];
    }

    if (key == 'city') {
      this.route.city = value;
    }
  }

  getRoute() {
    let routeId = localStorage.getItem('itemIdToEdit');
    if (routeId == '') {
      this.route = {
        price: 0,
        enable: false,
        name: '',
        country: 'Brasil',
        state: 'SC',
        city: 'Joinville',
        description: '',
        dificulty: this.routeDificulty[0],
        routeType: this.routeTypes[0],
        possibleActivities: '',
        services: '',
        warnning: '',
        estimatedDistanceKm: 0,
        estimatedTimeHour: 0,
        useTreasureQrCode: true,
        treasureQrCode: {},
        reward: {
          coins: 0,
          score: 0
        },
        baner: undefined,
        routeTreasure: undefined,
        treasureGeoLocalization: {
          latitude: 0,
          longitude: 0,
          elevation: 0
        },
        routeCheckpoints: undefined
      };

      this.refreshDropdowns();
      this.isLoading = false;
    } else {
      this.routeService.getById(routeId).subscribe((route: any) => {
        this.route = route;
        this.getTreasureQrCode();
        if (!this.route.reward)
          this.route.reward = {
            coins: 0,
            score: 0
          };
        this.isNewRoute = false;
        let country = this.countries.find(p => p.country == this.route.country);
        this.states = country.states;

        let state = this.states.find(c => c.initials == this.route.state);
        this.cities = state.cities;
        this.setPossibleActivitiesMap();
        this.isLoading = false;
        this.hour = Math.trunc(this.route.estimatedTimeHour);
        this.minutes = Math.round((this.route.estimatedTimeHour - Math.trunc(this.route.estimatedTimeHour))*60);
        console.log(this.route);
      }, error => {
        this.toastTr.error('erro ao buscar route');
        console.log(error);
      });
    }
  }

  backToList() {
    this.router.navigate(['AddOrEditRoute']);
    localStorage.setItem('itemIdToEdit', '');
  }

  refreshDropdowns() {
    let country = this.countries.find(p => p.country == this.route.country);
    this.states = country.states;

    let state = this.states.find(c => c.initials == this.route.state);
    this.cities = state.cities;
  }

  updateRoute(returnToList: boolean) {
    let routeId = localStorage.getItem('itemIdToEdit');
    if (routeId == '') {
      this.routeService.add(this.route).subscribe((route: any) => {
        this.route = route;
        console.log(this.route);
        this.isLoading = false;
        if (returnToList)
          this.backToList();
        else
          localStorage.setItem('itemIdToEdit', this.route.id);
      }, error => {
        this.toastTr.error("Erro when create route");
        console.log(error);
      });
    }
    else {
      this.isLoading = true;
      console.log(this.route);
      this.routeService.update(this.route).subscribe((route: any) => {
        this.route = route;
        this.isLoading = false;
        this.toastTr.success("Route atualizado com sucesso");
        this.isLoading = false;
        localStorage.setItem('itemIdToEdit', this.route.id);
        if (returnToList)
          this.backToList();
      }, error => {
        this.toastTr.success("Erro ao atualizar Route");
        console.log(error);
      });
    }
  }

  getBaner() {
    if (this.route.baner == null || this.route.baner == undefined) {
      return '../../assets/img/EditRoute/AddImagemPromocional.png';
    }
    else
      return this.route.baner.fileURL;
  }


  getCheckinImage(index, type) {
    if (type == 'local') {
      if (this.route.routeCheckpoints[index].localImage) {
        return this.route.routeCheckpoints[index].localImage.fileURL;
      }
      else
        return '../../assets/img/EditContact/NoImage.jpg';
    }
    else if (type == 'hint') {
      if (this.route.routeCheckpoints[index].hintImage) {
        return this.route.routeCheckpoints[index].hintImage.fileURL;
      }
      else
        return '../../assets/img/EditContact/NoImage.jpg';
    }
  }

  changeReward(type: string, input: any) {
    input.value = Math.floor(input.value);
    if (type == 'coin') {
      this.route.reward.coins = Number(input.value);
    }

    if (type == 'score') {
      this.route.reward.score = Number(input.value);
    }
  }

  changeCheckpointImage(event, index, type) {
    console.log(index);
    console.log(type);
    if (event.target.files && event.target.files.length) {

      let fileToAdd = event.target.files[0];
      this.isLoading = true;
      const reader = new FileReader();
      reader.readAsDataURL(fileToAdd);
      console.log(fileToAdd);

      reader.onload = () => {
        this.fileService.upload(fileToAdd, `Routes/${this.route.id}/Checkin_${index}`, 'Image').subscribe((sysFile: any) => {
          if (type == 'hint') {
            this.route.routeCheckpoints[index].hintImage = sysFile;
          }
          else if (type == 'local') {
            this.route.routeCheckpoints[index].localImage = sysFile;
          }
          this.updateRoute(false);
        }, error => {
          this.toastTr.error("Erro when upload route image");
          console.log(error);
        });
      }
    }
  }


  changeBanerImage(event) {
    if (event.target.files && event.target.files.length) {

      let fileToAdd = event.target.files[0];
      this.isLoading = true;
      const reader = new FileReader();
      reader.readAsDataURL(fileToAdd);
      console.log(fileToAdd);

      reader.onload = () => {
        var uploadImage = () => {
          this.fileService.upload(fileToAdd, `Routes/${this.route.id}`, 'Image').subscribe((sysFile: any) => {
            this.route.baner = sysFile;
            this.updateRoute(false);
          }, error => {
            this.toastTr.error("Erro when upload route image");
            console.log(error);
          });
        }
        let routeId = localStorage.getItem('routeIdToEdit');
        console.log(routeId);

        if (routeId == '') {
          this.routeService.add(this.route).subscribe((route: any) => {
            this.route = route;
            localStorage.setRoute('routeIdToEdit', this.route.id);
            uploadImage();
          }, error => {
            this.toastTr.error("Erro when create route");
            console.log(error);
          });
        }
        else {
          uploadImage();
        }
      }
    }
  }


  openRemoveCheckpointModal(template: TemplateRef<any>, idToRemove: string) {
    this.modalRef = this.modalService.show(template);
    this.checkpointToRemoveId = idToRemove;
    console.log(this.route);
  }

  removeCheckpoint() {
    let indexToRemove = this.route.routeCheckpoints.findIndex(r => r.id == this.checkpointToRemoveId);
    if (indexToRemove > -1) {
      this.route.routeCheckpoints.splice(indexToRemove, 1);
    }
    this.modalRef.hide();
    this.updateRoute(false);
  }

  downloadQRCodes() {
    this.isLoading = true;
    this.qrCodeService.getRouteQrCodes(this.route.id, 500).subscribe((file: Blob) => {
      this.isLoading = false;
      var a = document.createElement("a");
      document.body.appendChild(a);
      console.log(file);
      let urlObjt = window.URL.createObjectURL(file);
      a.href = urlObjt;
      a.download = `${this.route.name}_qrCodes.zip`;
      a.click();
      window.URL.revokeObjectURL(urlObjt);
    }, error => {
      this.toastTr.error("Erro ao baixar qr codes");
    });
  }

  SetQrCodes(enable: boolean)
  {
    this.route.useTreasureQrCode = enable;
    this.route.routeCheckpoints?.forEach(element => {
      element.useQrCode = enable;
    });
  }

  VerifyQrCodes()
  {
    let result = this.route.useTreasureQrCode
    this.route.routeCheckpoints?.forEach(element => {
      result |= element.useQrCode;
    })
    return result;
  }

}
