
<div *ngIf="isEditDialog() && isEmailConfirmed()">
    <router-outlet></router-outlet>
</div>
<div *ngIf="!isEditDialog()">
    <app-nav *ngIf="loggedIn()"></app-nav>
    <div class="container">
        <div *ngIf="!isEmailConfirmed() && loggedIn()" class="row mt-5 ml-auto mr-auto">
            <div class="col-3"></div>
            <div class="col-6">
                <h2 class="text-center pb-5">You need to confirm your email!</h2>
                <button class="w-100 btn btn-primary btn-lg" (click)="alreadyConfirmedButton()" type="submit">Already confirmed</button>                
            </div>
            <div class="col-3"></div>
        </div>
        <router-outlet *ngIf="isEmailConfirmed() || !loggedIn()"></router-outlet>
    </div>
</div>