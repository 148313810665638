<div *ngIf="!isLoading" class="col-md-7 col-lg-8 m-auto bg-light">
  <h4 class="mb-3 mt-3">New Partner</h4>
  <form  #f="ngForm" (ngSubmit)="onSubmit(f)"class="needs-validation" novalidate="">
    <div class="row g-3">

      <div class="col-12">
        <label for="partnerName" class="form-label">Partner Name</label>
        <input type="text" class="form-control" name="partnerName" [(ngModel)]='model.partnerName' id="partnerName" placeholder="Instituto Caranguejo de Educação Ambiental" required="">
        <div *ngIf="validateList.includes('PartnerName')" class="invalid-feedback d-block">
          Please enter your name.
        </div>
      </div>     

      <div class="col-12">
        <label for="userName" class="form-label">User Name</label>
        <div class="input-group has-validation">
          <input type="text" class="form-control" name="userName" [(ngModel)]='model.userName' id="userName" placeholder="Caranga" required="">
          <div *ngIf="validateList.includes('UserName')" class="invalid-feedback d-block">
            Your user name is required.
          </div>
          <div *ngIf="validateList.includes('UserNameDuplicated')" class="invalid-feedback d-block">            
            This name is being used
          </div>
        </div>
      </div>

      <div class="col-12">
        <label for="address" class="form-label">Address</label>
        <input type="text" class="form-control" name="address" [(ngModel)]='model.address' id="address" placeholder="1234 Main St" required="">
        <div *ngIf="validateList.includes('Address')" class="invalid-feedback d-block">
          Please enter your shipping address.
        </div>
      </div>

      <div class="col-md-5">
        <label for="country" class="form-label">Country</label>
        <select #country class="form-select" name="country" [(ngModel)]='model.country'  id="country" required="" 
        (change)="changeRegion('country', country.value)">
            <option *ngFor="let c of countries">{{c.country}}</option>
        </select>
        <div *ngIf="validateList.includes('Country')" class="invalid-feedback d-block">
          Please select a valid country.
        </div>
      </div>

      <div class="col-md-3">
        <label for="state" class="form-label">State</label>
        <select #state class="form-select" name="state" [(ngModel)]='model.state' id="state" required=""
        (change)="changeRegion('state', state.value)">
          <option *ngFor="let s of states">{{s.initials}}</option>
        </select>
        <div *ngIf="validateList.includes('State')" class="invalid-feedback d-block">
          Please provide a valid state.
        </div>
      </div>

      <div class="col-md-4">
        <label for="city" class="form-label">City</label>
        <select #city class="form-select" name="city" [(ngModel)]='model.city' id="city" required="">
          <option *ngFor="let c of cities">{{c}}</option>
        </select>
        <div *ngIf="validateList.includes('City')" class="invalid-feedback d-block">
          Please provide a valid city.
        </div>
      </div>

      <div class="col-sm-6">
        <label for="inChargeFirstName" class="form-label">In charge First name</label>
        <input type="text" class="form-control" name="inChargeFirstName" [(ngModel)]='model.inChargeFirstName'  id="inChargeFirstName" placeholder="" value="" required="">
        <div *ngIf="validateList.includes('InChargeFirstName')" class="invalid-feedback d-block">
          Valid first name is required.
        </div>
      </div>

      <div class="col-sm-6">
        <label for="inChargeLastName" class="form-label">In charge Last name</label>
        <input type="text" class="form-control" name="inChargeLastName" [(ngModel)]='model.inChargeLastName' id="inChargeLastName" placeholder="" value="" required="">
        <div *ngIf="validateList.includes('InChargeLastName')" class="invalid-feedback d-block">
          Valid last name is required.
        </div>
      </div>

      <div class="col-12">
        <label for="inChargeEmail" class="form-label">In charge Email</label>
        <input type="email" class="form-control" name="inChargeEmail" [(ngModel)]='model.inChargeEmail' id="inChargeEmail" placeholder="you@example.com" value="" required="">
        <div *ngIf="validateList.includes('InChargeEmail')" class="invalid-feedback d-block">
          Please enter a valid email address for shipping updates.
        </div>
        <div *ngIf="validateList.includes('UserEmailDuplicated')" class="invalid-feedback d-block">            
          This email is being used
        </div>
      </div>

    </div>
    <hr class="my-4">
    <button class="w-100 btn btn-primary btn-lg" type="submit">Add Partner</button>
  </form>
</div>


<div *ngIf="isLoading" class="d-flex justify-content-center " style="margin-top: 40vh; ">
  <div class="spinner-grow text-primary mb-auto " style="width: 5rem; height: 5rem; ">
      <span class="sr-only ">Loading...</span>
  </div>
</div>