import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './DashBoard.component.html',
  styleUrls: ['./DashBoard.component.css']
})
export class DashBoardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  isAdmin()
  {
    return localStorage.getItem('accessLevel') != undefined && localStorage.getItem('accessLevel').includes('Administrator');
  }

  isPartner()
  {
    return localStorage.getItem('accessLevel') != undefined && localStorage.getItem('accessLevel').includes('Partner');
  }

}
