import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  baseUrl = `${environment.BaseApiURL}/api/Route/`;

  constructor(private http: HttpClient) { }

  add(Route: any) {
    return this.http.post(`${this.baseUrl}`, Route);
  }

  addCheckpoint(RouteId: string) {
    if (RouteId == undefined)
      return this.http.get(`${this.baseUrl}AddCheckpoint`);
    else
      return this.http.get(`${this.baseUrl}AddCheckpoint/${RouteId}`);
  }

  getById(RouteId: string) {
    return this.http.get(`${this.baseUrl}${RouteId}`);
  }

  getAll() {
    return this.http.get(`${this.baseUrl}`);
  }

  update(Route: any) {
    return this.http.put(`${this.baseUrl}`, Route);
  }

  delete(RouteId: string) {
    return this.http.delete(`${this.baseUrl}${RouteId}`);
  }

}

