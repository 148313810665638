import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { VersionService } from '../_services/Version.service';

@Component({
  selector: 'app-login',
  templateUrl: './Login.component.html',
  styleUrls: ['./Login.component.css']
})
export class LoginComponent implements OnInit {
  model: any = [];
  isLoading = false;
  cmsVersion: string;
  apiVersion: string;

  constructor(private authService: AuthService,
    private versionService: VersionService,
    public router: Router,
    private toastr: ToastrService) { }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      this.router.navigate(['DashBoard']);
    }
    this.getCmsVersion();
    this.getApiVersion()
  }

  getCmsVersion() {
    this.cmsVersion = `cms: ${GlobalVariables.version}_${environment.Name}`;
  }

  getApiVersion() {
    this.versionService.get().subscribe((r: any) => {
      this.apiVersion = `api: ${r.version}`;
    })
  }

  login() {
    this.isLoading = true;
    this.authService.login({ userName: this.model.username, password: this.model.password }).subscribe(
      (success) => {
        if (success) {
          this.isLoading = false;
          this.router.navigate(['/DashBoard']);
          this.toastr.success('Logado com sucesso');
        }
        else
        {
          this.isLoading = false;
          this.toastr.error(`Você não tem permissão de acesso`);
        }
      },
      error => {
        this.isLoading = false;
        this.toastr.error(`Falha ao tentar Logar`);
        console.log(error);
      });
  }
}
