import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './_services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'Route Raiders';

  constructor(private authService: AuthService, private router: Router) { }

  loggedIn() {
    return this.authService.loggedIn();
  }

  isEditDialog() {
    return this.router.url == '/EditDialog';
  }

  isEmailConfirmed() {
    if (localStorage.getItem('accessLevel') == undefined) {
      return false;
    }

    if (!localStorage.getItem('accessLevel').includes('Administrator')) {
      if (localStorage.getItem('emailConfirmed') == 'true')
        return true;
      else
        return false;
    }
    else {
      return true;
    }
  }

  alreadyConfirmedButton() {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('accessLevel');
    this.router.navigate(['Login']);
  }

}



