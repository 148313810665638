import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../_services/User.service';

@Component({
  selector: 'app-AddPartner',
  templateUrl: './AddPartner.component.html',
  styleUrls: ['./AddPartner.component.css']
})
export class AddPartnerComponent implements OnInit {

  constructor(private userServices: UserService, private toastTr: ToastrService, private router: Router) { }

  countries: any = [];
  states: any = [];
  cities: any = [];
  isLoading: boolean = false;
  validateList: string[] = [];
  currentCountry: string;
  currentState: string;
  model: any = {
    "partnerName": "",
    "userName": "",
    "address": "",
    "country": "",
    "state": "",
    "city": "",
    "inChargeFirstName": "",
    "inChargeLastName": "",
    "inChargeEmail": ""
  };

  ngOnInit() {
    this.countries = GlobalVariables.places;
  }
  changeRegion(key: string = '', value: string = '') {
    let updateState = false;
    if (key == 'country') {
      updateState = this.currentCountry != undefined && this.currentCountry != value;
      this.currentCountry = value;
    }

    if (key == 'state')
      this.currentState = value;

    if (this.currentCountry == undefined || this.currentCountry == '')
      return;

    let c = this.countries.find(p => p.country == this.currentCountry);
    this.states = c.states;

    if (updateState)
      this.currentState = this.states[0].initials;

    if (this.currentState == undefined || this.currentState == '')
      return;

    let s = this.states.find(c => c.initials == this.currentState);
    this.cities = s.cities;
  }

  onSubmit(form: NgForm) {
    this.isLoading = true;
    const lastFormValues = form.value;
    this.userServices.CreateNew(form.value).subscribe(response => {
      this.router.navigate(['DashBoard']);
      this.toastTr.success("Novo Parceiro Adicionado com sucesso!", "NOVO PARCEIRO ADICIONADO");
      }, error => {
      console.log(error);
      this.isLoading = false;
      if (error.error != undefined) {
        this.validateList = error.error;
      }
      else {
        this.validateList = [];
      }
      this.toastTr.error("Erro ao adicionar novo parceiro!");
    });
  }
}
