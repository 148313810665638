<div *ngIf="!isLoading" class="block">
    <div class="row form-inline line-form mt-3">
        <div class="col-6 header">
            <h6>
                <div class="row">
                    <div class="col-1">
                        <img src="../../assets/img/EditItem/ItemImg.svg" alt="Item">
                    </div>
                    <div class="col-4">
                        <div *ngIf="isNewRoute" class="mt-2 ml-1 text-left" style="height: 100%;">
                            NEW ROUTE
                        </div>
                        <div *ngIf="!isNewRoute" class="mt-2 ml-1 text-left" style="height: 100%;">
                            EDIT ROUTE
                        </div>
                    </div>
                </div>
            </h6>
        </div>
    </div>
    <div class="row default-bg-p">
        <div class="col-2">
            <h6>Nome da rota:</h6>
        </div>
        <div class="col-10">
            <input [(ngModel)]="route.name" type="text">
        </div>
    </div>
    <div class="row default-bg-p">
        <div class="col-2">
            <h6>Região:</h6>
        </div>
        <div class="col-10">
            <div class="row">
                <div class="float-left col-4 btn-group" dropdown>
                    <button id="button" dropdownToggle type="button" class="dropdown-toggle"
                        aria-controls="dropdown">{{route.country}}<span class="caret"></span></button>
                    <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu"
                        aria-labelledby="button">
                        <div *ngFor="let c of countries">
                            <li (click)="changeRegion('country' ,c.country)" role="menuitem"><a class="dropdown-item"
                                    style="cursor: pointer;">{{c.country}}</a></li>
                        </div>
                    </ul>
                </div>
                <div class="float-left col-4 btn-group" dropdown>
                    <button id="button" dropdownToggle type="button" class="dropdown-toggle"
                        aria-controls="dropdown">{{route.state}}<span class="caret"></span></button>
                    <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu"
                        aria-labelledby="button">
                        <div *ngFor="let s of states">
                            <li (click)="changeRegion('state' ,s.initials)" role="menuitem"><a class="dropdown-item"
                                    style="cursor: pointer;">{{s.initials}}</a></li>
                        </div>
                    </ul>
                </div>
                <div class="float-left col-4 btn-group" dropdown>
                    <button id="button" dropdownToggle type="button" class="dropdown-toggle"
                        aria-controls="dropdown">{{route.city}}<span class="caret"></span></button>
                    <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu"
                        aria-labelledby="button">
                        <div *ngFor="let c of cities">
                            <li (click)="changeRegion('city' ,c)" role="menuitem"><a class="dropdown-item"
                                    style="cursor: pointer;">{{c}}</a></li>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="row default-bg-p">
        <div class="col-2">
            <h6>Tipo/Dificuldade:</h6>
        </div>
        <div class="col-10 float-left mr0">
            <div class="row">
                <div class="float-left col-4 btn-group" dropdown>
                    <button id="button" dropdownToggle type="button" class="dropdown-toggle"
                        aria-controls="dropdown">{{route.routeType}}<span class="caret"></span></button>
                    <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu"
                        aria-labelledby="button">
                        <div *ngFor="let t of routeTypes">
                            <li (click)="route.routeType = t" role="menuitem"><a class="dropdown-item"
                                    style="cursor: pointer;">{{t}}</a></li>
                        </div>
                    </ul>
                </div>
                <div class="float-left col-4 btn-group" dropdown>
                    <button id="button" dropdownToggle type="button" class="dropdown-toggle"
                        aria-controls="dropdown">{{route.dificulty}}<span class="caret"></span></button>
                    <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu"
                        aria-labelledby="button">
                        <div *ngFor="let d of routeDificulty">
                            <li (click)="route.dificulty = d" role="menuitem"><a class="dropdown-item"
                                    style="cursor: pointer;">{{d}}</a></li>
                        </div>
                    </ul>
                </div>
                <div class="col-4 float-left"></div>
            </div>
        </div>
    </div>
    <div class="row default-bg-p">
        <div class="col-2">
            <h6>Distância (Km):</h6>
        </div>
        <div class="col-2">
            <input [(ngModel)]="route.estimatedDistanceKm" type="number">
        </div>
        <div class="col-1"></div>
        <div class="col-2">
            <h6 class="float-right">Tempo estimado:</h6>
        </div>
        <div class="col-3">
            <div class="row">
                <div class="col-1">
                    H:
                </div>
                <div class="col-4">
                    <input [(ngModel)]="estimatedTimeHour" type="number" min="0" max="100">
                </div>
                <div class="col-1">
                    m:
                </div>
                <div class="col-4">
                    <input [(ngModel)]="estimatedTimeMinutes" type="number" min="0" max="59">
                </div>
                <div class="col-2">
                </div>
            </div>
        </div>
        <div class="col-2 float-left"></div>
    </div>
    <div class="row default-bg-m">
        <div class="col-12">
            <div class="row mb-2">
                <div class="col-12  d-flex justify-content-center">
                    <h6 class="text-center">Imagem de Apresentação</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-12  d-flex justify-content-center">
                    <img [src]="getBaner()" style="cursor: pointer;" alt="Baner" (click)="baner.click()">
                    <input type="file" #baner class="mx-auto btn-file" (change)="changeBanerImage($event)"
                        style="display: none;">
                </div>
            </div>
        </div>
    </div>
    <div class="row default-bg-p">
        <div class="col-2">
            <h6>É possivel realizar:</h6>
        </div>
        <div class="col-10">
            <div class="d-flex">
                <div *ngFor="let activitie of possibleActivitiesList;let i = index">
                    <div class="d-inline-flex activities">
                        <h6>{{activitie}}</h6>
                        <button [ngStyle]="{'background-color': activitieIsEnable(activitie) ? 'black' : 'transparent'}"
                            (click)="refreshActivities(activitie)"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row default-bg-m">
        <div class="col-12">
            <div class="row mt-3 mb-5" style="height: 150px;">
                <div class="col-2">
                    <h6>Atenção:</h6>
                </div>
                <div class="col-10">
                    <textarea [(ngModel)]="route.warnning" aria-label="With textarea"></textarea>
                </div>
            </div>
            <div class="row mb-5" style="height: 150px;">
                <div class="col-2">
                    <h6>Descrição da rota:</h6>
                </div>
                <div class="col-10">
                    <textarea [(ngModel)]="route.description" aria-label="With textarea"></textarea>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-2 ">
                    <h6>Serviços:</h6>
                </div>
                <div class="col-10 ">
                    <input [(ngModel)]="route.services ">
                </div>
            </div>
        </div>
    </div>
    <div class="row default-bg-p">
        <div class="col-2">
            <h6>Recompensa da rota:</h6>
        </div>
        <div class="col-10">
            <div class="row">
                <div class="col-2">
                    <h6>Moedas:</h6>
                </div>
                <div class="col-2">
                    <input #coins [(ngModel)]="route.reward.coins" (change)="changeReward('coin', coins)" type="number">
                </div>
                <div class="col-2">
                    <h6>Score:</h6>
                </div>
                <div class="col-2">
                    <input #score [(ngModel)]="route.reward.score" (change)="changeReward('score', score)"
                        type="number">
                </div>
            </div>
        </div>
    </div>
    <div class="row default-bg-p">
        <div class="col-2">
            <h6>Preço da rota:</h6>
        </div>
        <div class="col-2">
            <input #inputPrice [(ngModel)]="price" (change)="inputPrice.value = route.price" type="number">
        </div>
        <div class="col-8"></div>
    </div>
    <div class="row default-bg-m">
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-6 float-right text-right">
                    <h6>Tesouro:</h6>
                </div>
                <div class="col-6 float-left">
                    <div class="row">
                        <div class="float-left col-4 btn-group" dropdown>
                            <button id="button" dropdownToggle type="button" class="dropdown-toggle"
                                aria-controls="dropdown">{{routeTreasureName}}<span class="caret"></span></button>
                            <ul id="dropdown" #dropDownMenuTreasure *dropdownMenu class="dropdown-menu dropdown-scroll"
                                role="menu" aria-labelledby="button">
                                <div *ngFor="let t of treasures">
                                    <li (click)="route.routeTreasureId = t.id" role="menuitem">
                                        <a class="dropdown-item" style="cursor: pointer;">
                                            <h6 class="float-left mr-2" style="font-size: small;">{{t.name}}</h6>
                                            <img class="float-right"
                                                [src]="t ? t.imageFile.fileURL : '../../assets/img/EditItem/ItemImg.svg'"
                                                style="height: 25px;">
                                        </a>
                                    </li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12  d-flex justify-content-center">
                    <img [src]="getRouteTreasureImage()" alt="treasure image">
                </div>
            </div>
        </div>
    </div>
    <div class="row default-bg-p">
        <div class="col-2">
            <h6>Localização do tesouro:</h6>
        </div>
        <div class="col-10">
            <div class="row">
                <div class="col-1">
                    <h6>Latitude:</h6>
                </div>
                <div class="col-3">
                    <input [(ngModel)]="route.treasureGeoLocalization.latitude" type="number">
                </div>
                <div class="col-1">
                    <h6>Longitude:</h6>
                </div>
                <div class="col-3">
                    <input [(ngModel)]="route.treasureGeoLocalization.longitude" type="number">
                </div>
                <div class="col-1">
                    <h6>Elevação:</h6>
                </div>
                <div class="col-3">
                    <input [(ngModel)]="route.treasureGeoLocalization.elevation" type="number">
                </div>
            </div>
        </div>
    </div>
    <div class="row default-bg-m">
        <div class="col-12 m-auto">
            <div class="d-inline-flex activities">
                <h6>Use treasure qr code</h6>
                <button [ngStyle]="{'background-color': route.useTreasureQrCode ? 'black' : 'transparent'}"
                    (click)="route.useTreasureQrCode = !route.useTreasureQrCode"></button>
            </div>
        </div>
        <div class="col-12" *ngIf="route.useTreasureQrCode">
            <div class="row mb-2">
                <div class="col-12  d-flex justify-content-center">
                    <h6 class="text-center">QrCode do tesouro</h6>
                </div>
            </div>
            <div class="row">
                <div *ngIf="route.treasureQrCode.qrCodeString && treasureQrCodeImageUlr"
                    class="col-12  d-flex justify-content-center">
                    <img [src]="treasureQrCodeImageUlr" style="cursor: pointer;" alt="QrCode">
                </div>
                <div *ngIf="!route.treasureQrCode.qrCodeString" class="col-12  d-flex justify-content-center">
                    <h6>o qr code será gerado apos salvar a rota</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="row default-bg-p" style="background-color: transparent;">
        <div class="col-6 float-right">
            <h5 class="text-left">CHECKPOINTS</h5>
        </div>
        <div class="col-6 float-right">
            <button class="button-add float-right" (click)="addCheckpoint()" type="text"> ADICIONAR NOVO CHECKPOINT
            </button>
        </div>
    </div>
    <div *ngIf="route.routeCheckpoints">
        <div *ngFor="let c of route.routeCheckpoints;let i = index">
            <div class="row default-bg-gg">
                <div class="col-12 m-auto">
                    <div class="d-inline-flex activities">
                        <h6>Use qr code</h6>
                        <button [ngStyle]="{'background-color': c.useQrCode ? 'black' : 'transparent'}"
                            (click)="c.useQrCode = !c.useQrCode"></button>
                    </div>
                </div>
                <div class="col-12">
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-8">
                            <h6 style="font-weight: bold;" class="mb-4 mt-2 text-center">Checkpoint {{i + 1}}</h6>
                        </div>
                        <div class="col-2">
                            <button class="float-right button-custom-trash"
                                (click)="openRemoveCheckpointModal(confirmRemoveCheckpoint, c.id) "><i
                                    class="fas fa-trash-alt "></i></button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="row mb-5" style="height: 150px;">
                                <div class="col-2">
                                    <h6>Descrição do Checkpoint:</h6>
                                </div>
                                <div class="col-10">
                                    <textarea [(ngModel)]="c.localInfoText" aria-label="With textarea"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <img class="rounded mx-auto d-block" [src]="getCheckinImage(i, 'local')"
                                style="cursor: pointer;" alt="Baner" (click)="localCheckpoint.click()">
                            <input type="file" #localCheckpoint class="mx-auto btn-file"
                                (change)="changeCheckpointImage($event, i, 'local')" style="display: none;">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="row mb-5" style="height: 150px;">
                                <div class="col-2">
                                    <h6>Descrição da Pista:</h6>
                                </div>
                                <div class="col-10">
                                    <textarea [(ngModel)]="c.hintText" aria-label="With textarea"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <img class="rounded mx-auto d-block" [src]="getCheckinImage(i, 'hint')"
                                style="cursor: pointer;" alt="Baner" (click)="hintCheckpoint.click()">
                            <input type="file" #hintCheckpoint class="mx-auto btn-file"
                                (change)="changeCheckpointImage($event, i, 'hint')" style="display: none;">
                        </div>
                    </div>
                    <div class="row default-bg-p">
                        <div class="col-2">
                            <h6>Localização:</h6>
                        </div>
                        <div class="col-10">
                            <div class="row">
                                <div class="col-1">
                                    <h6>Latitude:</h6>
                                </div>
                                <div class="col-3">
                                    <input [(ngModel)]="c.geoLocalization.latitude" type="number">
                                </div>
                                <div class="col-1">
                                    <h6>Longitude:</h6>
                                </div>
                                <div class="col-3">
                                    <input [(ngModel)]="c.geoLocalization.longitude" type="number">
                                </div>
                                <div class="col-1">
                                    <h6>Elevação:</h6>
                                </div>
                                <div class="col-3">
                                    <input [(ngModel)]="c.geoLocalization.elevation" type="number">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <button class="mx-auto d-block button-add" (click)="editDialog(c.id)">Editar
                                Dialogo</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3 mb-3">
        <div class="col-4">
            <button class="w-50 float-left btn btn-secondary " (click)="backToList() ">Voltar</button>
        </div>
        <div class="col-4 text-center">
            <button  *ngIf="VerifyQrCodes()" class="text-center w-50 btn btn-warning" style="font-weight: bold;"
                (click)="downloadQRCodes()">QRCODES</button>
        </div>
        <div class="col-4">
            <button class="mt-auto float-right w-50 btn btn-primary ml-auto "
                (click)="updateRoute(true) ">Atualizar</button>
        </div>
    </div>
    <div class="row mt-3 mb-3">
        <div class="col-4">
            <button class="w-50 float-left btn btn-secondary " (click)="SetQrCodes(false) ">Dont Use Any QrCodes</button>
        </div><div class="col-4">            
        </div>
        <div class="col-4">
            <button class="mt-auto float-right w-50 btn btn-primary ml-auto"
                (click)="SetQrCodes(true)">Use All QrCodes</button>
        </div>
    </div>
</div>





<div *ngIf="isLoading " class="d-flex justify-content-center " style="margin-top: 40vh; ">
    <div class="spinner-grow text-primary mb-auto " style="width: 5rem; height: 5rem; ">
        <span class="sr-only ">Loading...</span>
    </div>
</div>



<!-- Modal -->
<ng-template #confirmRemoveCheckpoint>
    <div class="modal-header ">
        <h4 class="modal-title pull-left ">Você deseja remover este CHECKPOINT? (isso removerá os dialogos relacionados
            a esse Checkpoint) </h4>
        <button type="button " class="close pull-right " aria-label="Close " (click)="modalRef.hide() ">
            <span aria-hidden="true ">&times;</span>
        </button>
    </div>
    <div class="modal-body ">
        <div class="d-flex mt-5 mb-3 ">
            <button class="mr-auto w-25 btn btn-primary " (click)="modalRef.hide() ">Cancelar</button>
            <button class="mt-auto w-25 btn btn-danger ml-auto " (click)="removeCheckpoint() ">REMOVER</button>
        </div>
    </div>
</ng-template>