<div *ngIf="!isLoading" class="block">
    <div class="row form-inline line-form mt-3">
        <div class="col-6">
            <h2 class="custom-h2">
                <img class="imgRouteText " src="../../assets/img/EditRoute/routeImg.svg" alt="Contato "> ROTAS
            </h2>
        </div>
        <div class="col-6">
            <button class="float-right btn-success-custom " (click)="edit('')"><i class="fas fa-plus mr-2"></i>ADICIONAR ROTAS</button>
        </div>
    </div>
    <div class="row filter">
        <div class="col-1">
            <div class="row mt-2">
                <h6 class="col-5" style="font-weight: bold; font-size: 20px;">FILTRO</h6>
            </div>
        </div>
        <div class="col-4">
            <div class="row mt-2">
                <h6 class="col-5 text-right">NOME:</h6>
                <input type="text" class="col-7" [(ngModel)]="filterName">
            </div>
        </div>
        <div class="col-1">
        </div>
    </div>
    <table class="table table-striped ">
        <thead class="thead-custom ">
            <tr>
                <th>IMAGEM</th>
                <th>NOME</th>
                <th>PAÍS</th>
                <th>ESTADO</th>
                <th>CIDADE</th>
                <th class="button-cel-custom "></th>
            </tr>
        </thead>
        <tbody *ngIf="filterRoutes.length > 0">
            <tr *ngFor="let route of filterRoutes ">
                <td>
                    <img [src]="getImage(route.id)" style="height: 50px;">
                </td>
                <td>{{route.name}}</td>
                <td>{{route.country}}</td>
                <td>{{route.state}}</td>
                <td>{{route.city}}</td>
                <td>
                    <div class="btn-group" data-toggle="buttons ">
                        <button class="button-active" (click)="changeStatatus(route.id)" data-toggle="buttons " [ngStyle]="{ 'background-color': route.enable ? '#7DF31D' : '#F31DD1'} "><i class="mt-2 fa-book-open fa"></i></button>
                        <button class="button-custom-edit" (click)="edit(route.id) "><i class="fas fa-pencil-alt"></i></button>
                        <button class="button-custom-trash" (click)="openRemoveRouteModal(confirmRemoveRoute, route.id) "><i class="fas fa-trash-alt "></i></button>
                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot *ngIf="filterRoutes.length == 0">
            <tr>
                <td colspan="7 " class="text-center ">
                    <h4>
                        Nenhuma rota encontrada
                    </h4>
                </td>
            </tr>
        </tfoot>
    </table>
</div>

<div *ngIf="isLoading " class="d-flex justify-content-center " style="margin-top: 40vh; ">
    <div class="spinner-grow text-primary mb-auto " style="width: 5rem; height: 5rem; ">
        <span class="sr-only ">Loading...</span>
    </div>
</div>

<!-- Modal -->
<ng-template #confirmRemoveRoute>
    <div class="modal-header ">
        <h4 class="modal-title pull-left ">Você deseja remover este Route?</h4>
        <button type="button " class="close pull-right " aria-label="Close " (click)="modalRef.hide() ">
<span aria-hidden="true ">&times;</span>
</button>
    </div>
    <div class="modal-body ">
        <div class="d-flex mt-5 mb-3 ">
            <button class="mr-auto w-25 btn btn-primary " (click)="modalRef.hide() ">Cancelar</button>
            <button class="mt-auto w-25 btn btn-danger ml-auto " (click)="removeRoute() ">REMOVER</button>
        </div>
    </div>
</ng-template>