<div *ngIf="!isLoading" class="block">
    <div class="text-center">
        <form class="form-signin" #loginForm="ngForm" (ngSubmit)="login()">
            <img class="mb-4" src="../../assets/img/Logo.svg" alt="" width="72" height="72">
            <label for="userName" class="sr-only">
        User
      </label>
            <input type="name" id="userName" class="form-custom form-control" placeholder="User" name="username" required [(ngModel)]="model.username">
            <label for="password" class="sr-only">
        Password
      </label>
            <input type="password" id="password" class="form-custom form-control" placeholder="Password" name="password" required [(ngModel)]="model.password">
            <button [disabled]="!loginForm.valid" [ngStyle]="{'cursor': loginForm.valid == true ? 'pointer' : 'default'}" class="btn-custom btn btn-lg btn-primary btn-block" type="submit">Login</button>
            <p class="p1-custom mt-2 mb-3 text-muted">Route Raiders © 2019</p>
            <p class="p2-custom text-muted">{{cmsVersion}}<br>{{apiVersion}}</p>
        </form>
    </div>
</div>
<div *ngIf="isLoading" class="d-flex justify-content-center" style="margin-top: 40vh; ">
    <div class="spinner-grow text-primary mb-auto " style="width: 5rem; height: 5rem;">
        <span class="sr-only">Carregando...</span>
    </div>
</div>