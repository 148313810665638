import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {
  
  baseUrl = `${environment.BaseApiURL}/api/qrcode/`;

  constructor(private http: HttpClient) { }

  getImageById(id: string, size: number): Observable<Blob> {
    return this.http.get(`${this.baseUrl}image/${id}?size=${size}`, { responseType: 'blob' });
  }
  
  getRouteQrCodes(id: string, size: number): Observable<Blob> {
    return this.http.get(`${this.baseUrl}route/${id}?size=${size}`, { responseType: 'blob' });
  }

}
