import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

baseUrl = `${environment.BaseApiURL}/api/contact/`;

  constructor(private http: HttpClient) { }

  getAllContacts() {
    return this.http.get(`${this.baseUrl}`);
  }
  
  addContact(contact: any = []) {
    return this.http.post(`${this.baseUrl}`, contact);
  }

  getContactById(characterId: string) {
    return this.http.get(`${this.baseUrl}${characterId}`);
  }

  updateContact(character: any = []) {
    return this.http.put(`${this.baseUrl}`, character);
  }
  
  removeContact(characterId: string) {
    return this.http.delete(`${this.baseUrl}${characterId}`);
  }

  ChangeStatus(characterId: string) {
    return this.http.get(`${this.baseUrl}ChangeStatus/${characterId}`);
  }
}
