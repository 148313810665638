import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashBoardComponent } from './DashBoard/DashBoard.component';
import { LoginComponent } from './Login/Login.component';
import { AddOrEditContactComponent } from './AddOrEditContact/AddOrEditContact.component';
import { AuthGuard } from './auth/auth.guard';
import { ContactsComponent } from './Contacts/Contacts.component';
import { AddOrEditItemComponent } from './AddOrEditItem/AddOrEditItem.component';
import { EditItemComponent } from './EditItem/EditItem.component';
import { AddOrEditComicsComponent } from './AddOrEditComics/AddOrEditComics.component';
import { EditComicComponent } from './EditComic/EditComic.component';
import { AddOrEditRouteComponent } from './AddOrEditRoute/AddOrEditRoute.component';
import { EditRouteComponent } from './EditRoute/EditRoute.component';
import { EditDialogComponent } from './EditDialog/EditDialog.component';
import { GameStoreComponent } from './GameStore/GameStore.component';
import { EditGameStoreItemComponent } from './EditGameStoreItem/EditGameStoreItem.component';
import { AddPartnerComponent } from './AddPartner/AddPartner.component';
import { AddOrScanVoucherComponent } from './AddOrScanVoucher/AddOrScanVoucher.component';
import { ProfileComponent } from './Profile/Profile.component';
import { ScanVoucherComponent } from './ScanVoucher/ScanVoucher.component';
import { AddOrEditPartnerComponent } from './AddOrEditPartner/AddOrEditPartner.component';


const routes: Routes = [
  { path: 'Login', component: LoginComponent},
  { path: 'DashBoard', component: DashBoardComponent, canActivate: [AuthGuard]},
  { path: 'AddOrEditContact', component: AddOrEditContactComponent, canActivate: [AuthGuard]},
  { path: 'AddOrEditItem', component: AddOrEditItemComponent, canActivate: [AuthGuard]},
  { path: 'AddOrEditComics', component: AddOrEditComicsComponent, canActivate: [AuthGuard]},
  { path: 'AddOrEditRoute', component: AddOrEditRouteComponent, canActivate: [AuthGuard]},
  { path: 'EditItem', component: EditItemComponent, canActivate: [AuthGuard]},
  { path: 'EditGameStoreItem', component: EditGameStoreItemComponent, canActivate: [AuthGuard]},
  { path: 'EditComic', component: EditComicComponent, canActivate: [AuthGuard]},
  { path: 'EditContact', component: ContactsComponent, canActivate: [AuthGuard]},
  { path: 'EditRoute', component: EditRouteComponent, canActivate: [AuthGuard]},
  { path: 'EditDialog', component: EditDialogComponent, canActivate: [AuthGuard]},
  { path: 'GameStore', component: GameStoreComponent, canActivate: [AuthGuard]},
  { path: 'AddPartner', component: AddPartnerComponent, canActivate: [AuthGuard]},
  { path: 'AddOrScanVoucher', component: AddOrScanVoucherComponent, canActivate: [AuthGuard]},
  { path: 'AddOrEditPartner', component: AddOrEditPartnerComponent, canActivate: [AuthGuard]},
  { path: 'Profile', component: ProfileComponent, canActivate: [AuthGuard]},
  { path: 'ScanVoucher', component: ScanVoucherComponent, canActivate: [AuthGuard]},
  { path: '', redirectTo: 'DashBoard', pathMatch: 'full'},
  { path: '**', redirectTo: 'DashBoard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
