<div class="row" *ngIf="showCamera">
  <div class="col-12 mt-5">
    <qr-scanner
    [debug]="false"
    [canvasWidth]="500"
    [canvasHeight]="500"
    [stopAfterScan]="true"
    [updateTime]="500">
  </qr-scanner>
</div>
</div>

<!-- Modal -->
<ng-template #infoModal>
  <div class="modal-header ">
      <h4 class="modal-title pull-left ">INFO</h4>
      <button type="button " class="close pull-right " aria-label="Close " (click)="closeModal() ">
<span aria-hidden="true ">&times;</span>
</button>
  </div>    
  <div *ngIf="processingQRCode" class="d-flex justify-content-center " style="margin-top: 40vh; ">
    <div class="spinner-grow text-primary mb-auto " style="width: 5rem; height: 5rem; ">
        <span class="sr-only ">Loading...</span>
    </div>
  </div>
  <div *ngIf="!processingQRCode" class="modal-body ">    
    {{info}}
    <hr>
    <div *ngIf="validQrCode">
      <button class="mr-auto w-25 btn btn-primary" (click)="closeModal()">Cancelar</button>
      <button class="mt-auto w-25 btn btn-danger ml-5" (click)="confirmVoucher()">CONFIRMAR</button>
    </div>
  </div>
</ng-template>

