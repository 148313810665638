import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  baseUrl = `${environment.BaseApiURL}/api/partner/`;

  constructor(private http: HttpClient) { }

  getProfile() {
    return this.http.get(`${this.baseUrl}profile`);
  }

  updatePartner(partner: any) {
    return this.http.put(`${this.baseUrl}`, partner);
  }

  getAll(){    
    return this.http.get(`${this.baseUrl}GetAll`);
  }

  getPartnerById(id: string){    
    return this.http.get(`${this.baseUrl}getPartnerById/${id}`);
  }
}
