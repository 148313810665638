import { TemplateRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ItemService } from '../_services/Item.service';

@Component({
  selector: 'app-AddOrEditItem',
  templateUrl: './AddOrEditItem.component.html',
  styleUrls: ['./AddOrEditItem.component.scss']
})
export class AddOrEditItemComponent implements OnInit {

  constructor(private modalService: BsModalService, public itemService: ItemService, public toastTr: ToastrService, private router: Router) { }
  isLoading: boolean = true;
  modalRef: BsModalRef;

  rarity: string[] = ['ALL'];
  types: string[] = ['ALL'];

  items: any = [];
  filterItems: any = [];
  filterByType: string = 'ALL';
  filterByRarity: string = 'ALL';
  filterByName: string = '';

  set filterName(value: string) {
    this.refreshFilter('name', value);
  }

  get filterName(): string {
    return this.filterByName;
  }


  itemToRemoveId: string;

  ngOnInit() {
    this.rarity = this.rarity.concat(GlobalVariables.Rarity);
    this.types = this.types.concat(GlobalVariables.items.types);
    this.getAllItems();
  }

  edit(itemId: string) {
    localStorage.setItem('itemIdToEdit', itemId);
    this.router.navigate(['EditItem']);
  }

  refreshFilter(key: string, value: string) {
    if (key == 'type') {
      this.filterByType = value;
    }
    if (key == 'rarity') {
      this.filterByRarity = value;
    }

    if (key == 'name') {
      this.filterByName = value;
    }
    
    this.filterItems = this.filterByName ? this.filtrarcontacts(this.filterByName) : this.items;

    if (this.filterByType == "ALL")
      this.filterItems = this.filterItems;
    else
      this.filterItems = this.filterItems.filter(i => i.type == this.filterByType);

    if (this.filterByRarity == "ALL")
      this.filterItems = this.filterItems;
    else
      this.filterItems = this.filterItems.filter(i => i.rarity == this.filterByRarity);

      
  }

  filtrarcontacts(filtrarPor: string): any[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.items.filter(
      f => f.name.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  getAllItems() {
    this.itemService.getAll().subscribe((items: any = []) => {
      this.items = items;
      this.filterItems = this.items;
      this.isLoading = false;
      this.toastTr.success("Itens Atualizados");
    }, error => {
      this.toastTr.error("Erro ao atualizar itens");
    });
  }
  getImage(id: string) {
    let item = this.items.find(i => i.id == id);
    if (item.imageFile == null || item.imageFile == undefined) {
      return '../../assets/img/EditContact/NoImage.jpg';
    }
    else
      return item.imageFile.fileURL;
  }

  changeStatatus(id: string) {
    let item = this.items.find(i => i.id == id);
    item.enable = !item.enable;
    this.itemService.Update(item).subscribe(() => {
      this.getAllItems();
    }, error => {
      this.toastTr.error("Erro ao mudar status");
      console.log(error);
    });

  }

  openRemoveItemModal(template: TemplateRef<any>, idToRemove: string) {
    this.modalRef = this.modalService.show(template);
    this.itemToRemoveId = idToRemove;
  }

  removeItem() {
    this.itemService.delete(this.itemToRemoveId).subscribe(() => {
      this.toastTr.success('Item Removido com sucesso');
      this.getAllItems();
      this.modalRef.hide();
    }, error => {
      console.log(error);      
      this.toastTr.error('Erro ao remover item');
    });
  }
}
