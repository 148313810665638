<div *ngIf="!isLoading" class="block">
    <div class="row form-inline line-form mt-3">
        <div class="col-6">
            <h2 class="custom-h2">
                <img class="imgItemText " src="../../assets/img/EditItem/ItemImg.svg" alt="Contato "> ITENS e TESOUROS
            </h2>
        </div>
        <div class="col-6">
            <button class="float-right btn-success-custom " (click)="edit('')"><i class="fas fa-plus mr-2"></i>ADICIONAR ITEM</button>
        </div>
    </div>
    <div class="row filter">
        <div class="col-1">
            <div class="row mt-2">
                <h6 class="col-5" style="font-weight: bold; font-size: 20px;">FILTRO</h6>
            </div>
        </div>
        <div class="col-4">
            <div class="row mt-2">
                <h6 class="col-5 text-right">NOME:</h6>
                <input type="text" class="col-7" [(ngModel)]="filterName">
            </div>
        </div>
        <div class="col-1">
        </div>
        <div class="col-3">
            <div class="row mt-2">
                <div class="col-2">
                    <h6>TIPO:</h6>
                </div>
                <div class="col-10 d-flex">
                    <div class="float-left btn-group" dropdown>
                        <button id="button" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown">{{filterByType}}<span class="caret"></span></button>
                        <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu" aria-labelledby="button">
                            <div *ngFor="let t of types">
                                <li (click)="refreshFilter('type',t)" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{t}}</a></li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="row mt-2">
                <div class="col-4">
                    <h6>RARIDADE:</h6>
                </div>
                <div class="col-8 d-flex">
                    <div class="float-left btn-group" dropdown>
                        <button id="button" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown">{{filterByRarity}}<span class="caret"></span></button>
                        <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu" aria-labelledby="button">
                            <div *ngFor="let r of rarity">
                                <li (click)="refreshFilter('rarity',r)" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{r}}</a></li>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <table class="table table-striped ">
        <thead class="thead-custom ">
            <tr>
                <th>IMAGEM</th>
                <th>NOME</th>
                <th>TIPO</th>
                <th>RARIDADE</th>
                <th class="button-cel-custom "></th>
            </tr>
        </thead>
        <tbody *ngIf="filterItems.length > 0">
            <tr *ngFor="let item of filterItems ">
                <td>
                    <img [src]="getImage(item.id)" style="height: 50px;">
                </td>
                <td>{{item.name}}</td>
                <td>{{item.type}}</td>
                <td>{{item.rarity}}</td>
                <td>
                    <div class="btn-group" data-toggle="buttons ">
                        <button class="button-active" (click)="changeStatatus(item.id)" data-toggle="buttons " [ngStyle]="{ 'background-color': item.enable ? '#7DF31D' : '#F31DD1'} "><i class="mt-2 fa-gem fa"></i></button>
                        <button class="button-custom-edit" (click)="edit(item.id) "><i class="fas fa-pencil-alt"></i></button>
                        <button class="button-custom-trash" (click)="openRemoveItemModal(confirmRemoveItem, item.id) "><i class="fas fa-trash-alt "></i></button>
                    </div>
                </td>
            </tr>
        </tbody>
        <tfoot *ngIf="filterItems.length == 0">
            <tr>
                <td colspan="7 " class="text-center ">
                    <h4>
                        Nenhum item encontrado
                    </h4>
                </td>
            </tr>
        </tfoot>
    </table>
</div>

<div *ngIf="isLoading " class="d-flex justify-content-center " style="margin-top: 40vh; ">
    <div class="spinner-grow text-primary mb-auto " style="width: 5rem; height: 5rem; ">
        <span class="sr-only ">Loading...</span>
    </div>
</div>

<!-- Modal -->
<ng-template #confirmRemoveItem>
    <div class="modal-header ">
        <h4 class="modal-title pull-left ">Você deseja remover este Item?</h4>
        <button type="button " class="close pull-right " aria-label="Close " (click)="modalRef.hide() ">
  <span aria-hidden="true ">&times;</span>
</button>
    </div>
    <div class="modal-body ">
        <div class="d-flex mt-5 mb-3 ">
            <button class="mr-auto w-25 btn btn-primary " (click)="modalRef.hide() ">Cancelar</button>
            <button class="mt-auto w-25 btn btn-danger ml-auto " (click)="removeItem() ">REMOVER</button>
        </div>
    </div>
</ng-template>