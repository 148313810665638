<div class="container mt-4">
    <div class="row mbr-justify-content-center">
        <div *ngIf="isAdmin()" class="col-lg-6 mbr-col-md-10">
            <a href="AddOrEditContact" class="wrap">
                <div class="ico-wrap">
                    <span class="mbr-iconfont fa-user fa"></span>
                </div>
                <div class="text-wrap vcenter">
                    <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5"><span>CONTATOS</span></h2>
                    <p class="mbr-fonts-style text1 mbr-text display-6"></p>
                </div>
            </a>
        </div>
        <div *ngIf="isAdmin()" class="col-lg-6 mbr-col-md-10">
            <a href="AddOrEditItem" class="wrap">
                <div class="ico-wrap">
                    <span class="mbr-iconfont fa-gem fa"></span>
                </div>
                <div class="text-wrap vcenter">
                    <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5"><span>ITENS</span></h2>
                    <p class="mbr-fonts-style text1 mbr-text display-6"></p>
                </div>
            </a>
        </div>
        <div *ngIf="isAdmin()" class="col-lg-6 mbr-col-md-10">
            <a href="AddOrEditComics" class="wrap">
                <div class="ico-wrap">
                    <span class="mbr-iconfont fa-book-open fa"></span>
                </div>
                <div class="text-wrap vcenter">
                    <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5"><span>QUADRINHOS</span></h2>
                    <p class="mbr-fonts-style text1 mbr-text display-6"></p>
                </div>
            </a>
        </div>
        <div *ngIf="isAdmin()" class="col-lg-6 mbr-col-md-10">
            <a href="AddOrEditRoute" class="wrap">
                <div class="ico-wrap">
                    <span class="mbr-iconfont fa-map-marked-alt fa"></span>
                </div>
                <div class="text-wrap vcenter">
                    <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5"><span>ROTAS</span></h2>
                    <p class="mbr-fonts-style text1 mbr-text display-6"></p>
                </div>
            </a>
        </div>
        <div *ngIf="isAdmin()" class="col-lg-6 mbr-col-md-10">
            <a href="GameStore" class="wrap">
                <div class="ico-wrap">
                    <span class="mbr-iconfont fas fa-store fa"></span>
                </div>
                <div class="text-wrap vcenter">
                    <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5"><span>LOJA</span></h2>
                    <p class="mbr-fonts-style text1 mbr-text display-6"></p>
                </div>
            </a>
        </div>
        <div *ngIf="isAdmin()" class="col-lg-6 mbr-col-md-10">
            <a href="AddOrEditPartner" class="wrap">
                <div class="ico-wrap">
                    <span class="mbr-iconfont fas fa fa-rocket"></span>
                </div>
                <div class="text-wrap vcenter">
                    <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5"><span>Patners</span></h2>
                    <p class="mbr-fonts-style text1 mbr-text display-6"></p>
                </div>
            </a>
        </div>
        <div *ngIf="isPartner()" class="col-lg-6 mbr-col-md-10">
            <a href="Profile" class="wrap">
                <div class="ico-wrap">
                    <span class="mbr-iconfont fa-user fa"></span>
                </div>
                <div class="text-wrap vcenter">
                    <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5"><span>Profile</span></h2>
                    <p class="mbr-fonts-style text1 mbr-text display-6"></p>
                </div>
            </a>
        </div>
        <div *ngIf="isPartner()" class="col-lg-6 mbr-col-md-10">
            <a href="AddOrScanVoucher" class="wrap">
                <div class="ico-wrap">
                    <span class="mbr-iconfont fas fa-ticket-alt"></span>
                </div>
                <div class="text-wrap vcenter">
                    <h2 class="mbr-fonts-style mbr-bold mbr-section-title3 display-5"><span>Vouchers</span></h2>
                    <p class="mbr-fonts-style text1 mbr-text display-6"></p>
                </div>
            </a>
        </div>
    </div>
</div>