import { Component, OnInit, TemplateRef } from '@angular/core';
import { ContactService } from '../_services/contact.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ArchetypeService } from '../_services/archetype.service';
import { SystemFIleService } from '../_services/SystemFIle.service';

@Component({
  selector: 'app-Contacts',
  templateUrl: './Contacts.component.html',
  styleUrls: ['./Contacts.component.css']
})
export class ContactsComponent implements OnInit {

  isNewContact: boolean;
  modalRef: BsModalRef;
  isLoading: boolean;
  contact: any = [];

  fileToAdd: File;
  fileUrlPreview: any;

  places: any = [];
  states: any = [];
  cities: any = [];

  archetypes: any = [];

  removeMsg: string;
  typeToRemove: string;
  idToRemove: string;

  confirmMsg: string;
  confirmAction: any;

  tagList: any = [];

  speakToAdd: any =
    {
      tags:
        [
          {
            type: "CONTACTSPEAK",
            name: "GLOBAL",
            tagParamter1: null,
            tagParamter2: null,
            tagParamter3: null

          }
        ],
      text: '',
      isDefault: false
    };

  constructor(private modalService: BsModalService
    , private contactService: ContactService, private archetypeService: ArchetypeService, private fileService: SystemFIleService
    , private toastTr: ToastrService, private router: Router) { }

  reloadCitiesAndStates() {
    this.states = this.places.find(p => p.country == this.contact.nationality).states;
    var searchState = this.states.find(s => s.initials == this.contact.state);
    if (searchState == null || searchState == undefined) {
      this.contact.state = this.states[0].initials;
    }

    this.cities = this.states.find(p => p.initials == this.contact.state).cities;
    var searchCity = this.cities.find(c => c == this.contact.city);

    if (searchCity == null || searchCity == undefined) {
      this.contact.city = this.cities[0];
    }

  }

  changeRarity(rarity: string) {
    this.contact.rarity = rarity;
  }

  changeArchetype(archetype: string) {
    this.contact.archetype = archetype;
  }

  getPoses() {
    return this.contact.contactImages.filter(ci => ci.type == 'pose');
  }

  getParameterType(tagSpeakToAdd: any, parameterId: number) {
    let tagObj = this.tagList.find(t => t.name == tagSpeakToAdd.name);
    if (tagObj.parameters[parameterId].options != null || tagObj.parameters[parameterId].options != null
      || tagObj.parameters[parameterId].type == "COUNTRY" || tagObj.parameters[parameterId].type == "STATE"
      || tagObj.parameters[parameterId].type == "CITY" || tagObj.parameters[parameterId].type == "NAME"
      || tagObj.parameters[parameterId].type == "ENTITY")
      return "list";
    if (tagObj.parameters[parameterId].type == "NUMBER")
      return "number";
    return null;

  }

  getParamList(speakToAddtag: any, parameterId: number) {
    let tagObj = this.tagList.find(t => t.name == speakToAddtag.name);

    if (tagObj.parameters[parameterId].type == "COUNTRY") {
      let countryList: any = [];
      countryList.push("ALL");
      this.places.forEach(p => {
        if (p.country != "Desconhecido")
          countryList.push(p.country.toUpperCase());
      });
      return countryList;
    }

    if ((tagObj.parameters[parameterId].type == "STATE")) {
      let statesList: any = [];
      statesList.push("ALL");
      let place = this.places.find(p => p.country.toUpperCase() == speakToAddtag.parameter1);
      if (place != null && place != undefined)
        place.states.forEach(s => {
          statesList.push(s.initials.toUpperCase());
        });
      return statesList;
    }

    if (tagObj.parameters[parameterId].type == "CITY") {
      let citiesList: any = [];
      citiesList.push("ALL");
      let place = this.places.find(p => p.country.toUpperCase() == speakToAddtag.parameter1);
      let state: any = [];
      if (place != null && place != undefined)
        state = place.states.find(s => s.initials == speakToAddtag.parameter2);
      if (place != null && place != undefined && state != null && state != undefined)
        state.cities.forEach(c => {
          citiesList.push(c.toUpperCase());
        });
      return citiesList;
    }

    if (tagObj.parameters[parameterId].options != null)
      return tagObj.parameters[parameterId].options

    if (tagObj.parameters[parameterId].type == "ENTITY")
      return ['ALL']

    return [];
  }

  changeParameter(tagIndex: number, parameterIndex: number, param: string) {
    if (parameterIndex == 0) {
      this.speakToAdd.tags[tagIndex].parameter1 = param;
    }
    if (parameterIndex == 1) {
      this.speakToAdd.tags[tagIndex].parameter2 = param;
    }
    if (parameterIndex == 2) {
      this.speakToAdd.tags[tagIndex].parameter3 = param;
    }
  }

  changeParamNumber(tagIndex: number, paramIndex: number, changeTo: number) {
    let tag = this.tagList.find(t => t.name == this.speakToAdd.tags[tagIndex].name);
    let range = tag.parameters[paramIndex].range;

    if (range != null) {
      if (changeTo > range[1]) {
        changeTo = range[1];
      }
      else if (changeTo < range[0]) {
        changeTo = range[0];
      }
    }

    if (paramIndex == 0)
      this.speakToAdd.tags[tagIndex].parameter1 = changeTo;
    if (paramIndex == 1)
      this.speakToAdd.tags[tagIndex].parameter2 = changeTo;
    if (paramIndex == 2)
      this.speakToAdd.tags[tagIndex].parameter3 = changeTo;

  }

  changeTag(tagName: string, tagIndex: number) {
    this.speakToAdd.tags[tagIndex].name = tagName;
    let tag = this.tagList.find(t => t.name == tagName);
    if (tag.parameters.length > 0) {
      if (this.getParameterType(this.speakToAdd.tags[tagIndex], 0) == 'list')
        this.speakToAdd.tags[tagIndex].parameter1 = this.getParamList(this.speakToAdd.tags[tagIndex], 0)[0];
      else {
        this.changeParamNumber(tagIndex, 0, 0);
      }
      if (tag.parameters.length > 1) {
        if (this.getParameterType(this.speakToAdd.tags[tagIndex], 1) == 'list')
          this.speakToAdd.tags[tagIndex].parameter2 = this.getParamList(this.speakToAdd.tags[tagIndex], 1)[0];
        else
          this.changeParamNumber(tagIndex, 1, 0);
        if (tag.parameters.length > 2) {
          if (this.getParameterType(this.speakToAdd.tags[tagIndex], 2) == 'list')
            this.speakToAdd.tags[tagIndex].parameter3 = this.getParamList(this.speakToAdd.tags[tagIndex], 2)[0];
          else
            this.changeParamNumber(tagIndex, 2, 0);
        }
      }
    }
    else {
      this.speakToAdd.tags[tagIndex].parameter1 = null;
      this.speakToAdd.tags[tagIndex].parameter2 = null;
      this.speakToAdd.tags[tagIndex].parameter3 = null;
    }
  }


  addTagToSpeak() {
    let tag = {
      type: "CONTACTSPEAK",
      name: "GLOBAL"
    }
    this.speakToAdd.tags.push(tag);
    console.log(this.speakToAdd);
  }

  addSpeak() {
    var addSpeak = () => {
      let speakToAdd =
      {
        text: this.speakToAdd.text,
        tags: []
      };
      this.speakToAdd.tags.forEach(t => {
        let tagToAdd =
        {
          type: "CONTACTSPEAK",
          name: `${t.name.toUpperCase()}${((t.parameter1 == undefined || t.parameter1 == null) ? "" : "_" + t.parameter1.toString().toUpperCase())}${((t.parameter2 == undefined || t.parameter2 == null) ? "" : "_" + t.parameter2.toString().toUpperCase())}${((t.parameter3 == undefined || t.parameter3 == null) ? "" : "_" + t.parameter3.toString().toUpperCase())}` 
        };
        speakToAdd.tags.push(tagToAdd);
      });

      this.contact.contactSpeaks.push(speakToAdd);
      this.speakToAdd = {
        contactSpeakTags: [],
        text: '',
        isDefault: false
      };
      console.log("Speaks to add");
      console.log(this.contact.contactSpeaks);
      this.updateContact(false);
    }

    let contactId = localStorage.getItem('itemIdToEdit');
    if (contactId == '') {
      this.contactService.addContact(this.contact).subscribe((contact: any) => {
        this.contact = contact;
        console.log(this.contact);
        localStorage.setItem('itemIdToEdit', contact.id);
        addSpeak();
      }, error => {
        this.toastTr.error("Erro ao adicionar novo contato");
        console.log(error);
      });
    } else {
      addSpeak();
    }
  }


  removeTagToSpeak() {
    if (this.speakToAdd.tags.length > 1) {
      this.speakToAdd.tags = this.speakToAdd.tags.slice(0, -1);
    }
    console.log(this.speakToAdd);
  }

  setConfirmMsg(confirmAction: any) {
    this.confirmMsg = `Gostaria de adicionar a tag: ${confirmAction.param1.toUpperCaseCase()}`;
    this.confirmAction = confirmAction;
  }

  cofirmModalSubmit() {
    this.modalRef.hide();
  }

  ngOnInit() {
    this.isLoading = true;
    this.tagList = GlobalVariables.tags;
    this.places = GlobalVariables.places;
    this.getArchetypes();
    this.getContact();

  }

  getArchetypes(dontSetArchetype: boolean = false) {
    this.archetypeService.getAllArchetypes().subscribe((archetypes: any = []) => {
      this.archetypes = archetypes;
      if (this.archetypes.length > 0 && this.isNewContact && !dontSetArchetype)
        this.contact.archetype = this.archetypes[0].name;
    }, error => {
      this.toastTr.error(`Erro ao carregar arquetipos`);
      console.log(error);
    });
  }

  roundToInt(_number: number) {
    return Math.floor(_number);
  }

  getContact() {
    let contactId = localStorage.getItem('itemIdToEdit');

    if (contactId == '') {
      this.isNewContact = true;
      this.contact =
      {
        enable: false,
        reward: {
          coins: 0,
          score: 0
        },
        rarity: 'Classic',
        name: '',
        nationality: 'Brasil',
        state: 'SC',
        city: 'Joinville',
        description: '',
        archetype: this.archetypes.length > 0 ? this.archetypes[0].name : '',
        contactImages: [],
        contactSpeaks: []
      }

      this.reloadCitiesAndStates();
      this.isLoading = false;
      return;
    }
    this.contactService.getContactById(contactId).subscribe((contact: any = []) => {
      this.contact = contact;
      if (this.contact.reward == null || this.contact.reward == undefined) {
        this.contact.reward = {
          coins: 0,
          score: 0
        };
      }
      this.reloadCitiesAndStates();
      this.isLoading = false;
      console.log(this.contact);
    }, error => {
      this.toastTr.error(`Erro ao acessar o dados deste personagem`);
      this.isLoading = false;
      console.log(error);
    });
  }

  back() {
    this.router.navigate(['AddOrEditContact']);
  }

  updateContact(returnToList: boolean = true) {
    this.isLoading = true;

    let contactId = localStorage.getItem('itemIdToEdit');
    if (contactId == '') {
      this.contactService.addContact(this.contact).subscribe((contact: any) => {
        console.log("Create");
        this.contact = contact;
        console.log(this.contact);
        localStorage.setItem('itemIdToEdit', contact.id);
        this.router.navigate(['AddOrEditContact']);
      }, error => {
        this.toastTr.error("Erro ao adicionar novo contato");
        console.log(error);
      });
    } else {
      console.log("Update");
      console.log(this.contact);
      this.contactService.updateContact(this.contact).subscribe(contact => {
        console.log(contact);
        this.contact = contact;
        this.toastTr.success('Personagem Atualizado');
        if (returnToList)
          this.router.navigate(['AddOrEditContact']);
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
        this.toastTr.error(`Erro ao Atualizar o personagem`);
      });
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  addArchetype(archetype: string) {
    let newArchetype =
    {
      name: archetype
    }
    this.archetypeService.addArchetype(newArchetype).subscribe((archetype: any) => {
      console.log(archetype);
      this.getArchetypes(true);
      this.modalRef.hide();
    }, error => {
      console.log(error);
      this.modalRef.hide();
    });
  }

  getPoseToAdd() {
    return "../../assets/img/EditContact/NoImage.jpg"
  }

  getImagePortrait() {
    if (this.contact.contactImages.length > 0) {
      const contactImage = this.contact.contactImages.find(ci => ci.type == 'portrait' && ci.isDefault);
      if (contactImage != null && contactImage != undefined) {
        return contactImage.systemFile.fileURL;
      }
      else {
        return '../../assets/img/EditContact/NoImage.jpg';
      }
    } else {
      return '../../assets/img/EditContact/NoImage.jpg';
    }
  }
  AddPose(event, poseName: string) {
    if (event.target.files && event.target.files.length) {

      let fileToAdd = event.target.files[0];
      this.isLoading = true;
      const reader = new FileReader();
      reader.readAsDataURL(fileToAdd);
      reader.onload = () => {
        var uploadPotrait = (path: string) => {
          console.log(path);
          this.fileService.upload(fileToAdd, path, 'Image').subscribe((sysFile: any) => {
            console.log(sysFile);
            let imageContactToAdd =
            {
              type: 'pose',
              tag: poseName,
              systemFile: sysFile,
              isDefault: false,
              contactId: this.contact.id
            };

            this.contact.contactImages.push(imageContactToAdd);
            this.contactService.updateContact(this.contact).subscribe((contact: any) => {
              this.contact = contact;
              this.modalRef.hide();
              this.toastTr.success('Personagem Atualizado');
            }, error => {
              this.toastTr.success('Erro ao atualizar personagem');
              console.log(error);
            })
            this.isLoading = false;
          }, error => {
            this.toastTr.error("Erro ao Dar upload");
            console.log(error);
            this.isLoading = false;
          });
        }
        let contactId = localStorage.getItem('itemIdToEdit');

        if (contactId == '') {
          this.contactService.addContact(this.contact).subscribe((contact: any) => {
            this.contact = contact;
            console.log(this.contact);
            localStorage.setItem('itemIdToEdit', contact.id);
            uploadPotrait(`Contacts/${this.contact.id}/Poses`);
          }, error => {
            this.toastTr.error("Erro ao adicionar novo contato");
            console.log(error);
          });
        }
        else {
          uploadPotrait(`Contacts/${this.contact.id}/Poses`);
        }

      }
    }
  }

  changePortrait(event) {
    if (event.target.files && event.target.files.length) {
      let fileToAdd = event.target.files[0];
      this.isLoading = true;
      const reader = new FileReader();
      reader.readAsDataURL(fileToAdd);
      reader.onload = () => {
        var uploadPotrait = (path: string) => {
          console.log(path);
          this.fileService.upload(fileToAdd, path, 'Image').subscribe((sysFile: any) => {
            console.log(sysFile);
            let imageContactToAdd =
            {
              type: 'portrait',
              tag: 'default',
              systemFile: sysFile,
              isDefault: true
            };
            console.log(imageContactToAdd);
            this.contact.contactImages.push(imageContactToAdd);

            this.contactService.updateContact(this.contact).subscribe((contact: any) => {
              this.contact = contact;
              this.toastTr.success('Personagem Atualizado');
            }, error => {
              this.toastTr.error('Erro ao atualizar personagem');
              console.log(error);
            })
            this.isLoading = false;
          }, error => {
            this.toastTr.error("Erro ao Dar upload");
            console.log(error);
            this.isLoading = false;
          });
        }

        let ContactId = localStorage.getItem('itemIdToEdit');
        if (ContactId == '') {
          this.contactService.addContact(this.contact).subscribe((contact: any) => {
            this.contact = contact;
            console.log(this.contact);
            localStorage.setItem('itemIdToEdit', contact.id);
            uploadPotrait(`Contacts/${this.contact.id}/Portrait`);
          }, error => {
            this.toastTr.error("Erro ao adicionar novo contato");
            console.log(error);
          });
        }
        else {
          let newContactImages = this.contact.contactImages.filter(ci => ci.type != 'portrait')
          this.contact.contactImages = newContactImages;
          uploadPotrait(`Contacts/${this.contact.id}/Portrait`);
        }

      }
    }
  }

  setRemove(id: string, removeType: string) {

    this.typeToRemove = removeType;
    this.idToRemove = id;

    if (removeType == 'speak') {
      this.removeMsg = 'Voce deseja remover esta fala?';
    }
    else if (this.typeToRemove == 'pose') {
      this.removeMsg = 'Voce deseja remover a POSE selecionado?';
    }
  }

  remove() {
    this.isLoading = true;
    if (this.typeToRemove == 'speak') {
      this.contact.contactSpeaks = this.contact.contactSpeaks.filter(cs => cs.id != this.idToRemove);
      this.modalRef.hide();
      this.updateContact(false);
    }
    else if (this.typeToRemove == 'pose') {
      this.contact.contactImages = this.contact.contactImages.filter(ci => ci.id != this.idToRemove);
      this.contactService.updateContact(this.contact).subscribe((Contact: any) => {
        this.contact = Contact;
        this.toastTr.success('Contato Atualizado');
        this.modalRef.hide();
        this.isLoading = false;
      }, error => {
        this.toastTr.success('Erro ao atualiza contato');
        this.isLoading = false;
        console.log(error);
      });
    }
  }
}

