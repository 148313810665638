import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../_services/partner.service';
import { SystemFIleService } from '../_services/SystemFIle.service';

@Component({
  selector: 'app-AddOrEditPartner',
  templateUrl: './AddOrEditPartner.component.html',
  styleUrls: ['./AddOrEditPartner.component.scss']
})
export class AddOrEditPartnerComponent implements OnInit {
  constructor(private fileService: SystemFIleService, private partnerServices: PartnerService,
              private modalService: BsModalService, public partnerService: PartnerService, 
              public toastTr: ToastrService, private router: Router) { }

  isLoading: boolean = true;
  modalRef: BsModalRef;

  rarity: string[] = ['ALL'];
  types: string[] = ['ALL'];

  partners: any = [];
  partnerSelected: any;
  filterPartners: any = [];
  filterByType: string = 'ALL';
  filterByRarity: string = 'ALL';
  filterByName: string = '';

  set filterName(value: string) {
    this.refreshFilter('name', value);
  }

  get filterName(): string {
    return this.filterByName;
  }

  partnerToRemoveId: string;

  ngOnInit() {
    this.getAllPartner();
  }

  AddVoucher() {
    this.modalRef.hide();
    localStorage.setItem('partnerId', this.partnerSelected.id);
    this.router.navigate(['AddOrScanVoucher']);
  }

  add() {
    this.router.navigate(['AddPartner']);
  }

  edit(id: string, template: TemplateRef<any>) {
    this.isLoading = true;
    this.partnerService.getPartnerById(id).subscribe((partner: any) => {
      console.log(partner);
      this.modalRef = this.modalService.show(template);
      this.partnerSelected = partner;
      this.isLoading = false;
    }, error => {
      this.toastTr.error("Erro ao atualizar buscar parceiro");
    });

  }

  refreshFilter(key: string, value: string) {

    if (key == 'name') {
      this.filterByName = value;
    }

    this.filterPartners = this.filterByName ? this.filtrarcontacts(this.filterByName) : this.partners;
  }

  filtrarcontacts(filtrarPor: string): any[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.partners.filter(
      f => f.partnerName.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  getAllPartner() {
    this.partnerService.getAll().subscribe((partners: any = []) => {
      this.partners = partners;
      this.filterPartners = this.partners;
      this.isLoading = false;
      this.toastTr.success("Parceiros Atualizados");
    }, error => {
      this.toastTr.error("Erro ao atualizar os Parceiros");
    });
  }

  getImage(id: string) {
    let partner = this.partners.find(i => i.id == id);
    if (partner.partnerLogo == null || partner.partnerLogo == undefined) {
      return '../../assets/img/EditContact/NoImage.jpg';
    }
    else
      return partner.partnerLogo.fileURL;
  }


  changePartnerImage(event) {
    if (event.target.files && event.target.files.length) {

      let fileToAdd = event.target.files[0];
      this.isLoading = true;
      const reader = new FileReader();
      reader.readAsDataURL(fileToAdd);

      reader.onload = () => {
        this.isLoading = true;
        this.fileService.upload(fileToAdd, `Partners/${this.partnerSelected.id}`, 'Image').subscribe((sysFile: any) => {
          this.partnerSelected.partnerLogo = sysFile;
          this.isLoading = false;
          this.modalRef.hide();
          this.updatePartnerSelected();
        }, error => {
          this.toastTr.error("Erro when upload partner image");
          console.log(error);
        });
      }
    }
  }

  updatePartnerSelected() {
    this.isLoading = true;
    this.partnerServices.updatePartner(this.partnerSelected).subscribe(res => {
      this.getAllPartner();
    }, error => {
      console.log(error);
      this.toastTr.error("Erro when update partner image");
    });
  }

}
