import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs';
import { PartnerService } from '../_services/partner.service';
import { VoucherService } from '../_services/Voucher.service';

@Component({
  selector: 'app-AddOrScanVoucher',
  templateUrl: './AddOrScanVoucher.component.html',
  styleUrls: ['./AddOrScanVoucher.component.scss']
})
export class AddOrScanVoucherComponent implements OnInit {

  constructor(public partnerService: PartnerService, private modalService: BsModalService, private voucherServices: VoucherService, private toastTr: ToastrService, private router: Router) { }

  modalRef: BsModalRef;
  isLoading: boolean = true;
  partner: any;
  voucherList: any = [];
  voucherToEdit: any;
  Routes: any;
  validateList: string[] = [];
  model: any = {
    routeId: "",
    name: "",
    description: "",
    quantity: "",
    exprireOn: "2025-08-19"
  };
  voucherInfo: any;

  ngOnInit() {
    this.start();
  }

  start(){
    if (localStorage.getItem('accessLevel').includes('Administrator')) {
      if (localStorage.getItem('partnerId') != undefined && localStorage.getItem('partnerId') != null) 
      {
        this.getDisponibleRoutes();
        this.getPartnerById();
      }
      else
      {
        this.router.navigate(['AddOrEditVoucher']);
        this.toastTr.error('Erro ao carregar parceiro');
      }
    }
    else
    {
      this.getVoucherList()
      this.getDisponibleRoutes();
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    from
  }

  getPartnerById(){
    this.isLoading = true;
    this.partnerService.getPartnerById(localStorage.getItem('partnerId')).subscribe((partner: any) => {
      console.log(partner);
      this.voucherList = partner.voucherGroup;
      this.partner = partner;
      this.isLoading = false;
      this.toastTr.success('Lista atualizada');
    }, error => {
      this.toastTr.error("Erro ao atualizar buscar parceiro");
    });
  }

  getVoucherList() {
    this.isLoading = true;
    this.voucherServices.getMyVoucherGroups().subscribe(response => {
      this.isLoading = false;
      this.toastTr.success('Lista atualizada');
      this.voucherList = response;
      console.log(this.voucherList);
    }, error => {
      this.toastTr.error('erro ao atualizar a lista');
    })
  }
  
  getDisponibleRoutes() {    
    this.voucherServices.getDisponibleRoutes().subscribe(response => {
      this.Routes = response;     
    });
  }

  onSubmit(form: NgForm) {
    form.value.partnerId = this.partner.id;
    console.log(form.value);
    this.voucherServices.createVouchers(form.value).subscribe(res => {
      this.toastTr.success('Vouchers Adicionados com sucesso');
      this.modalRef.hide();      
      this.start();
      form.resetForm();
    }, error => {
      console.log(error);
      this.toastTr.error('erro ao adicionar os vouchers');
      if (error.error != undefined)
        this.validateList = error.error;
    });
  }

  showVoucherInfo(template: TemplateRef<any>, voucher: any) {
    console.log(voucher);
    this.voucherInfo = voucher;
    this.voucherInfo.voucherRemains = this.calculateVoucherRemains(voucher);
    this.voucherInfo.redeemedVouchers = this.calculateRedeemedVouchers(voucher);
    this.modalRef = this.modalService.show(template);
  }

  calculateRedeemedVouchers(voucher: any) {
    return voucher.vouchers.filter(v => v.useAt == GlobalVariables.emptyDateTime).length;
  }

  calculateVoucherRemains(voucher: any) {
    return voucher.vouchers.filter(v => v.playerHasWonId == GlobalVariables.emptyGuid).length;
  }

  getDate(date: any) {
    var date = date.split('T')[0].split('-');
    return date[2] + "/" + date[1] + "/" + date[0];

  }

  getRouteName(routeId) {
    return this.Routes.filter(r => r.id == routeId)[0].name;
  }

  scanVoucher() {
    this.router.navigate(['ScanVoucher']);
  }
}
