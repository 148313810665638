import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { StoreService } from '../_services/Store.service';

@Component({
  selector: 'app-GameStore',
  templateUrl: './GameStore.component.html',
  styleUrls: ['./GameStore.component.scss']
})
export class GameStoreComponent implements OnInit {
  constructor(private modalService: BsModalService, public storeService: StoreService, public toastTr: ToastrService, private router: Router) { }
  isLoading: boolean = true;
  modalRef: BsModalRef;

  storeItems: any = [];
  filterItems: any = [];
  filterByName: string = '';

  set filterName(value: string) {
    this.refreshFilter('name', value);
  }

  get filterName(): string {
    return this.filterByName;
  }


  itemToRemoveId: string;

  ngOnInit() {
    this.getAllStoreItems();
  }

  edit(itemId: string) {
    localStorage.setItem('itemIdToEdit', itemId);
    this.router.navigate(['EditGameStoreItem']);
  }

  refreshFilter(key: string, value: string) {
    if (key == 'name') {
      this.filterByName = value;
    }    
    this.filterItems = this.filterByName ? this.filtrarcontacts(this.filterByName) : this.storeItems;      
  }

  filtrarcontacts(filtrarPor: string): any[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.storeItems.filter(
      f => f.name.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  getAllStoreItems() {
    this.storeService.getAll().subscribe((items: any = []) => {
      this.storeItems = items;
      this.filterItems = this.storeItems;
      this.isLoading = false;
      this.toastTr.success("Itens Atualizados");
    }, error => {
      this.toastTr.error("Erro ao atualizar itens");
    });
  }
  getImage(id: string) {
    let item = this.storeItems.find(i => i.id == id);
    if (item.imageFile == null || item.imageFile == undefined) {
      return '../../assets/img/EditContact/NoImage.jpg';
    }
    else
      return item.imageFile.fileURL;
  }

  changeStatatus(id: string) {
    let item = this.storeItems.find(i => i.id == id);
    item.enable = !item.enable;
    this.storeService.Update(item).subscribe(() => {
      this.getAllStoreItems();
    }, error => {
      this.toastTr.error("Erro ao mudar status");
      console.log(error);
    });

  }

  openRemoveItemModal(template: TemplateRef<any>, idToRemove: string) {
    this.modalRef = this.modalService.show(template);
    this.itemToRemoveId = idToRemove;
  }

  removeItem() {
    this.storeService.delete(this.itemToRemoveId).subscribe(() => {
      this.toastTr.success('Item Removido com sucesso');
      this.getAllStoreItems();
      this.modalRef.hide();
    }, error => {
      console.log(error);      
      this.toastTr.error('Erro ao remover item');
    });
  }
}
