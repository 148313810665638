import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { QrScannerComponent } from 'angular2-qrscanner';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { VoucherService } from '../_services/Voucher.service';

@Component({
  selector: 'app-ScanVoucher',
  templateUrl: './ScanVoucher.component.html',
  styleUrls: ['./ScanVoucher.component.css']
})
export class ScanVoucherComponent implements OnInit {
  constructor(private voucherServices: VoucherService, private router: Router, public toastTr: ToastrService, private modalService: BsModalService) { }

  ngOnInit() {
  }
  showCamera: boolean = true;
  info: string;
  modalRef: BsModalRef;
  qrCode: string;

  validQrCode: boolean = false;
  processingQRCode: boolean = true;

  @ViewChild(QrScannerComponent, { static: false }) qrScannerComponent: QrScannerComponent;
  @ViewChild('infoModal') modalTemplate: TemplateRef<any>;

  ngAfterViewInit() {
    this.startQrCode();
  }

  startQrCode() {
    this.qrScannerComponent.getMediaDevices().then(devices => {
      const videoDevices: MediaDeviceInfo[] = [];
      for (const device of devices) {
        if (device.kind.toString() === 'videoinput') {
          videoDevices.push(device);
        }
      }
      if (videoDevices.length > 0) {
          if (videoDevices.length > 1)
            this.qrScannerComponent.chooseCamera.next(videoDevices[1]);
          else
            this.qrScannerComponent.chooseCamera.next(videoDevices[0]);        
      }
    });

    this.qrScannerComponent.capturedQr.subscribe(result => {
      this.onFoundQrCode(result);
    });
    this.showCamera = true;
  }

  onFoundQrCode(result) {
    this.qrCode = result;
    this.showCamera = false;
    this.modalRef = this.modalService.show(this.modalTemplate);
    this.processingQRCode = true;

    this.voucherServices.verifyQrCode(result).subscribe((response: any) => {
    this.processingQRCode = false;
      this.validQrCode = true;
      this.info = `Nome: ${response.name} \nEmail: ${response.email}`;
    }, error => {
      console.log(error);
      this.processingQRCode = false;
      this.validQrCode = false;
      this.info = "ERRO, o qr code deve estar ativo no aplicativo do ganhador, ou o qr code em questão é inválido";
    });
  }

  closeModal(){
    window.location.reload();
  }

  confirmVoucher(){
    this.processingQRCode = true;
    this.voucherServices.confirmQrCode(this.qrCode).subscribe((response: any) => {
      this.processingQRCode = false;
      this.toastTr.success("QR CODE VALIDADO COM SUCESSO");
      this.router.navigate(['AddOrScanVoucher']);
      this.modalRef.hide();
    }, error => {
      this.processingQRCode = false;
      this.toastTr.error("ERRO", "ERRO AO VALIDAR QR CODE");
      this.router.navigate(['AddOrScanVoucher']);
      this.modalRef.hide();
    });
  }
}
