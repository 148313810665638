<div *ngIf="!isLoading" class="block">
    <div class="row form-inline line-form mt-3">
        <div class="col-6 header">
            <h6>
                <img src="../../assets/img/EditItem/ItemImg.svg" alt="Item"> QUADRINHO
            </h6>
        </div>
    </div>
    <div class="row body">
        <div class="col-2">
            <div class="row">
                <div class="col-12">
                    <img [src]="getImage()" style="cursor: pointer;" alt="noImage" (click)="imageItem.click()">
                    <input type="file" #imageItem class="mx-auto btn-file" (change)="changeComicImage($event)" style="display: none;">
                </div>
            </div>
        </div>
        <div class="col-10">
            <div class="row">
                <div class="col-4">
                    <div class="row">
                        <div class="col-2">
                            <h6>Nome:</h6>
                        </div>
                        <div class="col-10">
                            <input [(ngModel)]="comic.name" type="text">
                        </div>
                    </div>
                </div>
                <div class="col-8">
                    <div class="row">
                        <h6 class="col-2">Região:</h6>
                        <div class="float-left col-4 btn-group" dropdown>
                            <button id="button" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown">{{comic.country}}<span class="caret"></span></button>
                            <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu" aria-labelledby="button">
                                <div *ngFor="let p of places">
                                    <li (click)="changeRegion('country', p.country)" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{p.country}}</a></li>
                                </div>
                            </ul>
                        </div>
                        <div class="float-left col-2 btn-group" dropdown>
                            <button id="button" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown">{{comic.state}}<span class="caret"></span></button>
                            <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu" aria-labelledby="button">
                                <div *ngFor="let s of states">
                                    <li (click)="changeRegion('state', s.initials)" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{s.initials}}</a></li>
                                </div>
                            </ul>
                        </div>
                        <div class="float-left col-4 btn-group" dropdown>
                            <button id="button" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown">{{comic.city}}<span class="caret"></span></button>
                            <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu" aria-labelledby="button">
                                <div *ngFor="let c of cities">
                                    <li (click)="changeRegion('city', c)" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{c}}</a></li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-6">
                    <div class="row">
                        <div class="col-2">
                            <h6>Link:</h6>
                        </div>
                        <div class="col-10">
                            <input [(ngModel)]="comic.comicLinkUrl" type="text">
                        </div>
                    </div>
                </div>
                <div class="col-6" *ngIf="comic.comicLinkUrl != 'http://'">
                    <div class="row">
                        <div class="col-12">
                            <a [href]="comic.comicLinkUrl" target="_blank">
                                <h6 style="cursor: pointer;">Testar link</h6>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row reward mt-3">
        <div class="col-6">
            <div class="row">
                <div class="col-2">
                    <h6>Moedas:</h6>
                </div>
                <div class="col-2">
                    <input #coins [(ngModel)]="comic.reward.coins" (change)="changeReward('coin', coins)" type="number">
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-2">
                    <h6>Score:</h6>
                </div>
                <div class="col-2">
                    <input #score [(ngModel)]="comic.reward.score" (change)="changeReward('score', score)" type="number">
                </div>
            </div>
        </div>
    </div>
    <div class="row localization mt-3">
        <div class="col-4">
            <div class="row">
                <div class="col-3">
                    <h6>Latitude:</h6>
                </div>
                <div class="col-9">
                    <input [(ngModel)]="comic.geoLocalization.latitude" (change)="refreshLocalization()" type="number">
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="row">
                <div class="col-3">
                    <h6>Longitude:</h6>
                </div>
                <div class="col-9">
                    <input [(ngModel)]="comic.geoLocalization.longitude" (change)="refreshLocalization()" type="number">
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="row">
                <div class="col-3">
                    <h6>Elevação:</h6>
                </div>
                <div class="col-5">
                    <input [(ngModel)]="comic.geoLocalization.elevation" (change)="refreshLocalization()" type="number">
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex mt-3 mb-3">
        <button class="w-25 btn btn-secondary" (click)="backToList()">Voltar</button>
        <button class="mt-auto w-25 btn btn-primary ml-auto" (click)="updateComic(true)">Atualizar</button>
    </div>
</div>

<div *ngIf="isLoading" class="d-flex justify-content-center " style="margin-top: 40vh; ">
    <div class="spinner-grow text-primary mb-auto " style="width: 5rem; height: 5rem; ">
        <span class="sr-only ">Loading...</span>
    </div>
</div>