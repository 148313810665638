<div *ngIf="!isLoading" class="block">
  <div class="row form-inline line-form mt-3">
    <div class="col-6 p-3">
      <h2 class="custom-h2">
        <div *ngIf="partner">
          <img class="imgVoucherText " src="../../assets/img/EditVoucher/VoucherImg.svg" alt="Contato ">
          {{partner.partnerName}}
        </div>
        <div *ngIf="!partner">
          <img class="imgVoucherText " src="../../assets/img/EditVoucher/VoucherImg.svg" alt="Contato ">
          VOUCHERS
        </div>
      </h2>
    </div>
    <div class="col-6 p-3">
      <button *ngIf="partner" class="w-100 btn btn-primary" (click)="openModal(addVoucherModal)" type="submit">ADD
        VOUCHER</button>
      <button *ngIf="!partner" class="w-100 btn btn-primary mt-2" (click)="scanVoucher()" type="submit">SCAN
        VOUCHER</button>
    </div>
  </div>
  <table class="table table-striped ">
    <thead class="thead-custom ">
      <tr>
        <th>VOUCHER NAME</th>
        <th>Remains</th>
        <th class="button-cel-custom "></th>
      </tr>
    </thead>
    <tbody *ngIf="voucherList.length > 0">
      <tr *ngFor="let Voucher of voucherList ">
        <td>{{Voucher.giftName}}</td>
        <td>{{calculateVoucherRemains(Voucher)}}</td>
        <td>
          <div class="btn-group" data-toggle="buttons">
            <button *ngIf="partner" class="button-custom-edit" (click)="showVoucherInfo(infoModal, Voucher)"><i
                class="fas fa-pencil-alt"></i></button>
            <button *ngIf="!partner" class="button-custom-edit" (click)="showVoucherInfo(infoModal, Voucher)"><i
                class="fas fa-eye"></i></button>
          </div>
        </td>
      </tr>
    </tbody>
    <tfoot *ngIf="voucherList.length == 0">
      <tr>
        <td colspan="7 " class="text-center ">
          <h4>
            Nenhum voucher encontrado
          </h4>
        </td>
      </tr>
    </tfoot>
  </table>
</div>

<div *ngIf="isLoading " class="d-flex justify-content-center " style="margin-top: 40vh; ">
  <div class="spinner-grow text-primary mb-auto " style="width: 5rem; height: 5rem; ">
    <span class="sr-only ">Loading...</span>
  </div>
</div>

<!-- Modal -->
<ng-template #addVoucherModal>
  <div class="modal-header ">
    <h4 class="modal-title pull-left ">Novo Voucher</h4>
    <button type="button " class="close pull-right " aria-label="Close " (click)="modalRef.hide() ">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body ">
    <form #f="ngForm" (ngSubmit)="onSubmit(f)" class="needs-validation" novalidate="">
      <div class="row g-3">

        <div class="col-md-12">
          <label for="routeId" class="form-label">Route</label>
          <select class="form-select" name="routeId" [(ngModel)]='model.routeId' id="routeId" required="">
            <option *ngFor="let r of Routes" [value]="r.id">{{r.name}}</option>
          </select>
          <div *ngIf="validateList.includes('RouteId')" class="invalid-feedback d-block">
            Please provide a valid route.
          </div>
        </div>

        <div class="col-12">
          <label for="name" class="form-label">Voucher Name</label>
          <input type="text" class="form-control" name="name" [(ngModel)]='model.name' id="name"
            placeholder="Kit Aventureiro" required="">
          <div *ngIf="validateList.includes('Name')" class="invalid-feedback d-block">
            Please enter a Voucher name.
          </div>
        </div>

        <div class="col-12">
          <label for="description" class="form-label">Voucher Description</label>
          <div class="input-group has-validation">
            <textarea type="text" rows="5" class="form-control" name="description" [(ngModel)]='model.description'
              id="description" placeholder="1 garrifinha de água + 1 bolsa simples" required=""></textarea>
            <div *ngIf="validateList.includes('Description')" class="invalid-feedback d-block">
              Voucher Description is required.
            </div>
          </div>
        </div>

        <div class="col-12">
          <label for="quantity" class="form-label">Quantity</label>
          <input type="number" class="form-control" name="quantity" [(ngModel)]='model.quantity' id="quantity"
            placeholder="10" required="">
          <div *ngIf="validateList.includes('Quantity')" class="invalid-feedback d-block">
            Please enter a Quantity
          </div>
        </div>

        <div class="col-12">
          <label for="exprireOn" class="form-label">Expire on</label>
          <input name="exprireOn" [(ngModel)]='model.exprireOn' class="form-control" type="date" value="2025-08-19"
            id="exprireOn">
          <div *ngIf="validateList.includes('ExprireOn')" class="invalid-feedback d-block">
            Please enter a Expire Date.
          </div>
        </div>


      </div>
      <hr class="my-4">

      <div class="d-flex mt-5 mb-3">
        <button class="mr-auto btn btn-danger modal-buttons" (click)="modalRef.hide()">CANCELAR</button>
        <button class="mt-auto btn btn-primary ml-auto modal-buttons">ADICIONAR</button>
      </div>
    </form>

  </div>
</ng-template>


<!-- Modal -->
<ng-template #infoModal>
  <div class="modal-header ">
    <h4 class="modal-title pull-left ">Voucher info</h4>
    <button type="button " class="close pull-right " aria-label="Close " (click)="modalRef.hide() ">
      <span aria-hidden="true ">&times;</span>
    </button>
  </div>
  <div class="modal-body ">


    <div class="row g-3">
      <div class="col-12">
        <label for="name" class="form-label"><b>Route Name:</b> {{getRouteName(voucherInfo.routeId)}}</label>
      </div>
      <div class="col-12">
        <label for="name" class="form-label"><b>Voucher Name:</b> {{voucherInfo.giftName}}</label>
      </div>
      <div class="col-12">
        <label for="description" class="form-label"><b>Voucher Description:</b> {{voucherInfo.giftDescription}}</label>
      </div>
      <div class="col-12">
        <label for="exprireOn" class="form-label"><b>Expire on:</b> {{getDate(voucherInfo.expiresAt)}}</label>
      </div>
      <div class="col-12">
        <label for="quantity" class="form-label"><b>Quantity:</b> {{voucherInfo.vouchers.length}}</label>
      </div>
      <div class="col-12">
        <label for="exprireOn" class="form-label"><b>Remains vouchers:</b>
          {{calculateVoucherRemains(voucherInfo)}}</label>
      </div>
      <div class="col-12">
        <label for="exprireOn" class="form-label"><b>Reedeem vouchers:</b>
          {{calculateRedeemedVouchers(voucherInfo)}}</label>
      </div>
    </div>
  </div>
</ng-template>