<div *ngIf="!isLoading" class="block">
    <div class="row form-inline line-form mt-3">
        <div class="col-6 header">
            <h6>
                <img src="../../assets/img/EditItem/ItemImg.svg" alt="Item"> ITENS e TESOUROS
            </h6>
        </div>
    </div>
    <div class="row body">
        <div class="col-4">
            <div class="row">
                <div class="col-12">
                    <img [src]="getImage()" style="cursor: pointer;" alt="noImage" (click)="imageItem.click()">
                    <input type="file" #imageItem class="mx-auto btn-file" (change)="changeItemImage($event)" style="display: none;">
                </div>
            </div>
        </div>
        <div class="col-8">
            <div class="row">
                <div class="col-6">
                    <div class="row">
                        <h6 class="col-4">Nome:</h6><input [(ngModel)]="item.name" class="col-8" type="text">
                    </div>
                </div>
                <div class="col-3">
                    <div class="row">
                        <h6 class="col-4">Tipo:</h6>
                        <div class="float-left col-8 btn-group" dropdown>
                            <button id="button" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown">{{item.type}}<span class="caret"></span></button>
                            <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu" aria-labelledby="button">
                                <div *ngFor="let t of types">
                                    <li (click)="item.type = t" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{t}}</a></li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <div class="row">
                        <h6 class="col-5">Raridade:</h6>
                        <div class="float-left col-7 btn-group" dropdown>
                            <button id="button" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown">{{item.rarity}}<span class="caret"></span></button>
                            <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu" aria-labelledby="button">
                                <div *ngFor="let r of rarity">
                                    <li (click)="item.rarity = r" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{r}}</a></li>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-6">
                    <h6>Descrição do Ítem</h6>
                </div>
                <div class="col-6">
                    <div *ngIf="isArtifact" class="col-12">
                        <div class="row">
                            <h6 class="col-6">Action:</h6>
                            <div class="float-left col-6 btn-group" dropdown>
                                <button id="button" dropdownToggle type="button" class="dropdown-toggle" aria-controls="dropdown">{{item.itemAction}}<span class="caret"></span></button>
                                <ul id="dropdown" *dropdownMenu class="dropdown-menu dropdown-scroll" role="menu" aria-labelledby="button">
                                    <div *ngFor="let a of actions">
                                        <li (click)="item.itemAction = a" role="menuitem"><a class="dropdown-item" style="cursor: pointer;">{{a}}</a></li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row description">
                <div class="col-12">
                    <textarea [(ngModel)]="item.description" class="col-12 mt-2" aria-label="With textarea"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="row reward mt-3">
        <div class="col-6">
            <div class="row">
                <h6>Moedas:</h6>
                <input #coins [(ngModel)]="item.reward.coins" (change)="changeReward('coin', coins)" type="number">
            </div>
        </div>
        <div class="col-6">
            <div class="row">
                <h6>Score:</h6>
                <input #score [(ngModel)]="item.reward.score" (change)="changeReward('score', score)" type="number">
            </div>
        </div>
    </div>
    <div class="d-flex mt-3 mb-3">
        <button class="w-25 btn btn-secondary" (click)="backToList()">Voltar</button>
        <button class="mt-auto w-25 btn btn-primary ml-auto" (click)="updateItem(true)">Atualizar</button>
    </div>
</div>

<div *ngIf="isLoading" class="d-flex justify-content-center " style="margin-top: 40vh; ">
    <div class="spinner-grow text-primary mb-auto " style="width: 5rem; height: 5rem; ">
        <span class="sr-only ">Loading...</span>
    </div>
</div>