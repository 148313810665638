import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../_services/partner.service';
import { SystemFIleService } from '../_services/SystemFIle.service';

@Component({
  selector: 'app-Profile',
  templateUrl: './Profile.component.html',
  styleUrls: ['./Profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(private fileService: SystemFIleService, private partnerServices: PartnerService, private toastTr: ToastrService, private router: Router) { }

  isLoading: boolean = true;
  partner: any;

  ngOnInit() {
    this.loadProfile();
  }

  loadProfile() {
    this.partnerServices.getProfile().subscribe(res => {
      this.partner = res;
      this.isLoading = false;
    }, error => {
      console.log(error);
    });
  }

  getImage() {
    if (this.partner.partnerLogo == null || this.partner.partnerLogo == undefined) {
      return '../../assets/img/EditContact/NoImage.jpg';
    }
    else
      return this.partner.partnerLogo.fileURL;
  }
}
