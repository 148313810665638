import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ContactService as ContactService } from '../_services/contact.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-addoreditContact',
  templateUrl: './AddOrEditContact.component.html',
  styleUrls: ['./AddOrEditContact.component.css']
})

export class AddOrEditContactComponent implements OnInit {
  
  modalRef: BsModalRef;
  contactsList: any = [];
  filterContacts: any = [];
  isLoading = true;
  FfiltroLista: string;

  contactToRemoveId: string;

  imagemAltura = 50;
  imagemMargem = 2;

  get filtroLista(): string {
    return this.FfiltroLista;
  }
  set filtroLista(value: string) {
    this.FfiltroLista = value;
    this.filterContacts = this.filtroLista ? this.filtrarcontacts(this.filtroLista) : this.contactsList;
  }
  constructor(private modalService: BsModalService, private contactService: ContactService, private toastTr: ToastrService, private router: Router) { }

  ngOnInit() {
    this.getContacts();
  }

  filtrarcontacts(filtrarPor: string): any[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.contactsList.filter(
      char => char.name.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  edit(contactId: string) {
    localStorage.setItem('itemIdToEdit', contactId);
    this.router.navigate(['EditContact']);
  }

  getContacts() {
    this.contactService.getAllContacts().subscribe((char: any[]) => {
      this.contactsList = char;
      console.log(this.contactsList);
      this.toastTr.info('Lista de Personagens Atualizada');
      this.isLoading = false;
      this.filtroLista = '';
    }, error => {
      this.toastTr.error(`Erro ao atualizar lista de personagens`);
      this.isLoading = false;
      console.log(error);
    });
  }

  addContact(name: string) {

    if (name == "" || name == undefined || name == null) {
      this.toastTr.error('Você precisa adicionar um nome');
      return;
    }

    this.isLoading = true;

    let newCaracter = {
      name: name
    };

    this.contactService.addContact(newCaracter).subscribe(() => {
      this.isLoading = false;
      this.toastTr.success('Personagem adicionado');
      this.modalRef.hide();
      this.getContacts();
    }, error => {
      this.isLoading = false;
      this.modalRef.hide();
      console.log(error.status);

      if (error.status === 409)
        this.toastTr.error('Já existe um personagem com este nome');
      else
        this.toastTr.error('Erro ao carregar o personagem');
    });
  }

  removeContact() {
    this.contactService.removeContact(this.contactToRemoveId).subscribe(() => {
      this.toastTr.success('Personagem Removido');
      this.modalRef.hide();
      this.getContacts();
    }, error => {
      this.toastTr.error('Erro ao remover o personagem');
      console.log(error);
    });
    console.log('Tentando remover o contact');
  }

  openRemoveContactModal(template: TemplateRef<any>, contactToRemoveId: string) {
    this.contactToRemoveId = contactToRemoveId;
    this.modalRef = this.modalService.show(template);
  }

  openAddModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  changeStatatus(id: string){
    this.isLoading = true;
    this.contactService.ChangeStatus(id).subscribe(() => {
      this.toastTr.success('Status do personagem alterado');
      this.getContacts();
      this.isLoading = false;

    }, error => {
      this.isLoading = false;
      this.toastTr.error('Erro ao alterar status');
      console.log(error);
    });
  }

  getImage(id: number) {
    const char = this.contactsList.find(c => c.id === id);
    if (char.contactImages.length > 0) {
      const contactImage = char.contactImages.find(ci => ci.type == 'portrait' && ci.isDefault);
      if(contactImage != null && contactImage != undefined)
      {        
        return contactImage.systemFile.fileURL;
      } 
      else
      {
        return '../../assets/img/EditContact/NoImage.jpg';
      }
    } else {
      return '../../assets/img/EditContact/NoImage.jpg';
    }
  }
}