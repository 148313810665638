import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {
  baseUrl = `${environment.BaseApiURL}/api/voucher/`;

  constructor(private http: HttpClient) { }

  
  getDisponibleRoutes() {
    return this.http.get(`${this.baseUrl}DisponibleRoutes`);
  }

  createVouchers(voucherGroupConfig: any) {
    return this.http.post(`${this.baseUrl}createVouchers`, voucherGroupConfig);
  }

  getMyVoucherGroups() {
    return this.http.get(`${this.baseUrl}getMyVouchers`);
  }

  
  verifyQrCode(qrCode) {
    return this.http.get(`${this.baseUrl}VerifyQrCode/${qrCode}`);
  }

  confirmQrCode(qrCode) {
    return this.http.get(`${this.baseUrl}confirmQrCode/${qrCode}`);
  }

}
