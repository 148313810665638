import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-EditDialog',
  templateUrl: './EditDialog.component.html',
  styleUrls: ['./EditDialog.component.css']
})
export class EditDialogComponent implements OnInit {


  constructor(private router: Router) { }

  ngOnInit() {
    unityInstance = window['UnityLoader'].instantiate('unityContainer', "../../assets/DialogEditor/Build/70612031a2e174d491b64fe2e8dd9a91.json");
  }

  backToRoute() {
    this.router.navigate(['EditRoute']);;
  }

}
