import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { RouteService } from '../_services/route.service';

@Component({
  selector: 'app-AddOrEditRoute',
  templateUrl: './AddOrEditRoute.component.html',
  styleUrls: ['./AddOrEditRoute.component.scss']
})
export class AddOrEditRouteComponent implements OnInit {
  constructor(private modalService: BsModalService, public routeService: RouteService, public toastTr: ToastrService, private router: Router) { }
  isLoading: boolean = true;
  modalRef: BsModalRef;

  rarity: string[] = ['ALL'];
  types: string[] = ['ALL'];

  routes: any = [];
  filterRoutes: any = [];
  filterByType: string = 'ALL';
  filterByRarity: string = 'ALL';
  filterByName: string = '';

  set filterName(value: string) {
    this.refreshFilter('name', value);
  }

  get filterName(): string {
    return this.filterByName;
  }

  routeToRemoveId: string;

  ngOnInit() {
    this.getAllRoutes();
  }

  edit(routeId: string) {
    localStorage.setItem('itemIdToEdit', routeId);
    console.log(routeId);
    this.router.navigate(['EditRoute']);
  }

  refreshFilter(key: string, value: string) {

    if (key == 'name') {
      this.filterByName = value;
    }

    this.filterRoutes = this.filterByName ? this.filtrarcontacts(this.filterByName) : this.routes;
  }

  filtrarcontacts(filtrarPor: string): any[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.routes.filter(
      f => f.name.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  getAllRoutes() {
    this.routeService.getAll().subscribe((routes: any = []) => {
      console.log(routes);
      this.routes = routes;
      this.filterRoutes = this.routes;
      this.isLoading = false;
      this.toastTr.success("Rotas Atualizadas");
    }, error => {
      this.toastTr.error("Erro ao atualizar as rotas");
    });
  }

  getImage(id: string) {
    let route = this.routes.find(i => i.id == id);
    if (route.baner) {
      return route.baner.fileURL;
    }
    else
      return '../../assets/img/EditContact/NoImage.jpg';
  }

  changeStatatus(id: string) {
    let route = this.routes.find(i => i.id == id);
    route.enable = !route.enable;
    this.routeService.update(route).subscribe(() => {
      this.getAllRoutes();
    }, error => {
      this.toastTr.error("Erro ao mudar status");
      console.log(error);
    });

  }

  openRemoveRouteModal(template: TemplateRef<any>, idToRemove: string) {
    this.modalRef = this.modalService.show(template);
    this.routeToRemoveId = idToRemove;
  }

  removeRoute() {
    this.routeService.delete(this.routeToRemoveId).subscribe(() => {
      this.toastTr.success('Route Removido com sucesso');
      this.getAllRoutes();
      this.modalRef.hide();
    }, error => {
      console.log(error);
      this.toastTr.error('Erro ao remover route');
    });
  }

}
