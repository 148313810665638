import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  baseUrl = `${environment.BaseApiURL}/api/item/`;

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get(`${this.baseUrl}`);
  }

  add(item: any) {
    return this.http.post(`${this.baseUrl}`, item);
  }

  Update(item: any) {
    return this.http.put(`${this.baseUrl}`, item);
  }

  getById(id: string) {
    return this.http.get(`${this.baseUrl}${id}`);
  }

  getAllArtifacts() {
    return this.http.get(`${this.baseUrl}Getitemsbytype/artifacts`);
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}${id}`);
  }

}
