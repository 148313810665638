import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl = `${environment.BaseApiURL}/api/user/`;

constructor(private http: HttpClient) { }

CreateNew(newPartner: any) {
  return this.http.post(`${this.baseUrl}CreatePartner`, newPartner);
}
}
