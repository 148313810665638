import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ComicsService } from '../_services/Comics.service';

@Component({
  selector: 'app-AddOrEditComics',
  templateUrl: './AddOrEditComics.component.html',
  styleUrls: ['./AddOrEditComics.component.scss']
})
export class AddOrEditComicsComponent implements OnInit {
  constructor(private modalService: BsModalService, public comicService: ComicsService, public toastTr: ToastrService, private router: Router) { }
  isLoading: boolean = true;
  modalRef: BsModalRef;

  rarity: string[] = ['ALL'];
  types: string[] = ['ALL'];

  comics: any = [];
  filterComics: any = [];
  filterByType: string = 'ALL';
  filterByRarity: string = 'ALL';
  filterByName: string = '';

  set filterName(value: string) {
    this.refreshFilter('name', value);
  }

  get filterName(): string {
    return this.filterByName;
  }

  comicToRemoveId: string;

  ngOnInit() {
    this.getAllComics();
  }

  edit(comicId: string) {
    localStorage.setItem('itemIdToEdit', comicId);
    this.router.navigate(['EditComic']);
  }

  refreshFilter(key: string, value: string) {

    if (key == 'name') {
      this.filterByName = value;
    }
    
    this.filterComics = this.filterByName ? this.filtrarcontacts(this.filterByName) : this.comics;      
  }

  filtrarcontacts(filtrarPor: string): any[] {
    filtrarPor = filtrarPor.toLocaleLowerCase();
    return this.comics.filter(
      f => f.name.toLocaleLowerCase().indexOf(filtrarPor) !== -1
    );
  }

  getAllComics() {
    this.comicService.getAll().subscribe((comics: any = []) => {
      this.comics = comics;
      this.filterComics = this.comics;
      this.isLoading = false;
      this.toastTr.success("Quadrinhos Atualizados");
    }, error => {
      this.toastTr.error("Erro ao atualizar quadrinhos");
    });
  }

  getImage(id: string) {
    let comic = this.comics.find(i => i.id == id);
    if (comic.imageFile == null || comic.imageFile == undefined) {
      return '../../assets/img/EditContact/NoImage.jpg';
    }
    else
      return comic.imageFile.fileURL;
  }

  changeStatatus(id: string) {
    let comic = this.comics.find(i => i.id == id);
    comic.enable = !comic.enable;
    this.comicService.Update(comic).subscribe(() => {
      this.getAllComics();
    }, error => {
      this.toastTr.error("Erro ao mudar status");
      console.log(error);
    });

  }

  openRemoveComicModal(template: TemplateRef<any>, idToRemove: string) {
    this.modalRef = this.modalService.show(template);
    this.comicToRemoveId = idToRemove;
  }

  removeComic() {
    this.comicService.delete(this.comicToRemoveId).subscribe(() => {
      this.toastTr.success('Comic Removido com sucesso');
      this.getAllComics();
      this.modalRef.hide();
    }, error => {
      console.log(error);      
      this.toastTr.error('Erro ao remover comic');
    });
  }
}
