import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactsComponent } from './Contacts/Contacts.component';
import { LoginComponent } from './Login/Login.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DashBoardComponent } from './DashBoard/DashBoard.component';
import { NavComponent } from './nav/nav.component';
import { AddOrEditContactComponent } from './AddOrEditContact/AddOrEditContact.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { AddOrEditItemComponent } from './AddOrEditItem/AddOrEditItem.component';
import { EditItemComponent } from './EditItem/EditItem.component';
import { AddOrEditComicsComponent } from './AddOrEditComics/AddOrEditComics.component';
import { EditComicComponent } from './EditComic/EditComic.component';
import { AddOrEditRouteComponent } from './AddOrEditRoute/AddOrEditRoute.component';
import { EditRouteComponent } from './EditRoute/EditRoute.component';
import { EditDialogComponent } from './EditDialog/EditDialog.component';
import { GameStoreComponent } from './GameStore/GameStore.component';
import { EditGameStoreItemComponent } from './EditGameStoreItem/EditGameStoreItem.component';
import { AddPartnerComponent } from './AddPartner/AddPartner.component';
import { AddOrScanVoucherComponent } from './AddOrScanVoucher/AddOrScanVoucher.component';
import { ProfileComponent } from './Profile/Profile.component';
import { NgQrScannerModule } from 'angular2-qrscanner';
import { ScanVoucherComponent } from './ScanVoucher/ScanVoucher.component';
import { AddOrEditPartnerComponent } from './AddOrEditPartner/AddOrEditPartner.component';

@NgModule({
   declarations: [															
      AppComponent,
      ContactsComponent,
      LoginComponent,
      DashBoardComponent,
      NavComponent,
      AddOrEditContactComponent,
      AddOrEditItemComponent,
      EditItemComponent,
      AddOrEditComicsComponent,
      EditComicComponent,
      AddOrEditRouteComponent,
      EditRouteComponent,
      EditDialogComponent,
      GameStoreComponent,
      EditGameStoreItemComponent,
      AddPartnerComponent,
      AddOrScanVoucherComponent,
      ProfileComponent,
      ScanVoucherComponent,
      AddOrEditPartnerComponent
   ],
   imports: [
      BrowserAnimationsModule,
      BrowserModule,
      AppRoutingModule,
      ToastrModule.forRoot(),
      BsDropdownModule.forRoot(),
      ModalModule.forRoot(),
      TooltipModule.forRoot(),
      CommonModule,
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
      NgQrScannerModule.forRoot()
   ],
   providers: [
      BsModalRef,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
